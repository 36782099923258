import React from "react";
import * as echarts from "echarts";

type PropTypes = {
    title: string,
    dates: Promise<Array<string>>,
    data: Promise<Array<any>>
}

class BaseChart extends React.Component<PropTypes, any> {
    constructor(props: PropTypes) {
        super(props);

        this.renderChart = this.renderChart.bind(this);
    }

    renderChart(dates: Array<string>, data: Array<any>): void {
        const chartDom = document.getElementById(`${this.props.title}Chart`);
        const chart = echarts.init(chartDom as HTMLElement);
        const option = {
            tooltip: {
                trigger: "axis",
                position: function (pt: any[]) {
                    return [pt[0], "10%"];
                },
            },
            title: {
                left: "center",
                text: "",
            },
            grid: {
                top: 10,
                left: 45,
                right: 35,
            },
            toolbox: {
                show: false,
                feature: {
                    dataZoom: {
                        yAxisIndex: "none",
                    },
                    restore: {},
                    saveAsImage: {},
                },
            },
            xAxis: {
                type: "category",
                boundaryGap: false,
                data: dates,
            },
            yAxis: {
                type: "value",
                boundaryGap: [0, "100%"],
            },
            dataZoom: [

            ],
            series: [
                {
                    name: `${this.props.title} data`,
                    type: "line",
                    smooth: true,
                    symbol: "none",
                    sampling: "average",
                    itemStyle: {
                        color: "rgb(255, 70, 131)",
                    },
                    areaStyle: {
                        color:
                            (
                                [
                                    {
                                        offset: 0,
                                        color: "rgba(255, 158, 68,0.2)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(255, 70, 131)",
                                    },
                                ]),
                    },
                    data: data,
                },
            ],
        };

        option && chart.setOption(option);
    }


    render() {
        this.props.dates.then(dates => {
            this.props.data.then(data => {
                this.renderChart(dates, data);
            });
        });
        return(
            <div className="col-lg-12 col-md-12">
                <div className="card">
                    <div className="header">
                        <h2>{this.props.title}</h2>
                    </div>
                    <div className="body">
                        <div id={`${this.props.title}Chart`} style={{height: 250}}/>
                    </div>
                </div>
            </div>
        );
    }

}

export default BaseChart;