export const SET_CURRENT_USER = "userReducer/SET_CURRENT_USER";
export const UNSET_CURRENT_USER = "userReducer/SET_CURRENT_USER";

export const onCurrentUserChange = (val) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: val,
  });
};

export const onCurrentUserUnload = () => (dispatch) => {
  dispatch({
    type: UNSET_CURRENT_USER,
    payload: null,
  });
};
