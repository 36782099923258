import React from "react";
import { connect } from "react-redux";
import { onLoggedIn } from '../../actions';
import Layout from '../../components/public/Layout';
import ToolsService from '../../Services/ToolsService';
import AuthService from '../../Services/AuthService';
import UserData from '../../Models/UserData';
import image from "../../assets/images/user-small.png";

interface StateTypes {
  isLoad?: boolean;
  email: string | null;
  password: string | null;
}
interface PropTypes {
  currentUser: UserData,
  currentUserLoaded: boolean
  onLoggedIn: (state:boolean) => {}
}

class Lockscreen extends React.Component<PropTypes, StateTypes> {
  constructor(props: PropTypes | Readonly<PropTypes>) {
    super(props);

    this.state = {
      isLoad: true,
      email: null,
      password: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLoginStateChange = this.handleLoginStateChange.bind(this);
    this.unlock = this.unlock.bind(this);
  }

  unlock() {
    const authService = new AuthService();
    const email = String(this.props.currentUser.username);
    const password = String(this.state.password);

    // @ts-ignore
    authService.loginUser(email, password).then(() => {
      this.handleLoginStateChange(true);
      this.redirect();
    });
  }

  redirect() {
    ToolsService.navigateTo('/login');
  }

  handleLoginStateChange(state: boolean) {
    this.props.onLoggedIn(state);
  }

  handleSubmit(e: any) {
    e.preventDefault();
    this.unlock();
  }

  handleChange(e: React.FormEvent<HTMLInputElement>) {
    const name = e.currentTarget.name as keyof typeof this.state;
    // @ts-ignore
    this.setState({[name]: e.currentTarget.value});
  };

  renderName() {
    const { currentUser, currentUserLoaded } = this.props;

    if(!currentUserLoaded) {
      return 'Loading';
    }

    return (
        <>
          {currentUser.firstName}
          {' '}{currentUser.insertion}{currentUser?.insertion !== null ? ' ': null}
          {currentUser.lastName}
        </>
    );
  }

  renderEmail() {
    const { currentUser, currentUserLoaded } = this.props;

    if(!currentUserLoaded) {
      return 'Loading';
    }

    return (
        <>
          {currentUser.email}
        </>
    );
  }

  renderField(
      fieldName: string,
      type: string = 'text',
      required: boolean = false,
      placeholder: string = ''
  ) {
    const ph = (placeholder === '' ? `Your ${fieldName}` : placeholder);
    const fn = fieldName as keyof typeof this.state;
    const value = String(this.state[fn]);

    return (
        <div className="form-group">
          <label className="control-label sr-only">
            {ToolsService.capitalizeFirstLetter(fieldName)}
          </label>
          <input
              className="form-control"
              id={`signup-${fieldName}`}
              name={fieldName}
              placeholder={ph}
              required={required}
              type={type}
              value={value}
              onChange={this.handleChange}
          />
        </div>
    );
  }

  renderForm() {
    return (
        <>
          <div className="user text-center m-b-30">
            <img
                alt="Avatar"
                className="rounded-circle"
                src={image}
            />
            <h4 className="name m-t-10">{ this.renderName()}</h4>
            <p>{ this.renderEmail()}</p>
          </div>
          <form  className="ng-untouched ng-pristine ng-valid">

            {this.renderField(
                'password',
                'password',
                true
            )}

            <button onClick={this.handleSubmit} className="btn btn-primary btn-lg btn-block">Login</button>
          </form>

        </>
    );
  }

  render() {
    const title = '';

    return (
        <Layout
            title={title}
            forgotPassword={true}
            register={true}
            login={false}
            forgotPasswordLogin={false}
            // @ts-ignore
            loading={false}
            children={this.renderForm()}

        />
    );
  }

}



// @ts-ignore
const mapStateToProps = ({userReducer}) => ({
    currentUser: userReducer.currentUser,
    currentUserLoaded: userReducer.loaded,
});

export default connect(mapStateToProps, {
  onLoggedIn
// @ts-ignore
})(Lockscreen);
