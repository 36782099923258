import React from "react";
import GeneralModal from "./GeneralModal";

type PropTypes = {
    onConfirm: () => void;
    action: string|null;
}

class ConfirmationModal extends React.Component<PropTypes, any> {

    constructor(props: PropTypes) {
        super(props);

    }

    render() {
        return (
            <GeneralModal
                title={"Confirmation"}
                closeButtonText={"Cancel"}
                primaryButtonText={"Confirm"}
                bodyText={`Are you sure you want to ${this.props.action}?`}
                onSubmit={this.props.onConfirm}
            />
        )
    }

}

export default ConfirmationModal;