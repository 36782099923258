import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/Dashboard/Dashboard';

import Page403 from '../pages/Shared/Page403';
import Page404 from '../pages/Shared/Page404';
import Page500 from '../pages/Shared/Page500';
import Page503 from '../pages/Shared/Page503';
import Maintenance from '../pages/Shared/Maintenance';
import Logout from '../pages/Auth/Logout';
import Lockscreen from '../pages/Auth/Lockscreen';
import Layout from '../components/secured/Layout';
import Analytical from '../pages/Dashboard/Analytical';
import ActiveSubscriptions from '../pages/Products/ActiveSubscriptions';
import LastInvoices from "../pages/Invoices/LastInvoices";
import ProductsOverview from "../pages/Products/ProductsOverview";
import VpsOverview from "../pages/Vps/VpsOverview";
import VpsDetails from "../pages/Vps/VpsDetails";

export default function Authorized() {
  return (
      <Routes>


        <Route path="page403" element={<Layout children={<Page403/>}/>}/>
        <Route path="page404" element={<Layout children={<Page404/>}/>}/>
        <Route path="page500" element={<Layout children={<Page500/>}/>}/>
        <Route path="page503" element={<Layout children={<Page503/>}/>}/>
        <Route path="maintenance" element={<Layout children={<Maintenance/>}/>}/>

        <Route path="lockscreen" element={<Lockscreen/>}/>
        <Route path="logout" element={<Logout/>}/>

        <Route path="dashboard/analytical" element={<Layout children={<Analytical/>}/>}/>
        <Route path="dashboard" element={<Layout children={<Dashboard/>}/>}/>
          
        <Route path="products/active" element={<Layout  children={<ActiveSubscriptions />}/>} />
        <Route path="products/overview" element={<Layout children={<ProductsOverview />} />} />

        <Route path="invoices/recent" element={<Layout children={<LastInvoices />} />} />

        <Route path="vps/overview" element={<Layout children={<VpsOverview />} /> } />
        <Route path="vps/details" element={<Layout children={<VpsDetails />} /> } />

        <Route path="*" element={<Navigate to="/dashboard" replace/>}/>
      </Routes>
  );
};
