export const ON_LOGGED_IN = "loginReducer/ON_LOGGED_IN";
export const ON_LOCKED = "loginReducer/ON_LOCKED";
export const ON_ERROR = "loginReducer/ON_ERROR";
export const SET_ERROR_MESSAGE = "loginReducer/SET_ERROR_MESSAGE";

export const onLoggedIn = (val) => (dispatch) => {
  dispatch({
    type: ON_LOGGED_IN,
    payload: val,
  });
};

export const onLocked = (val) => (dispatch) => {
  dispatch({
    type: ON_LOCKED,
    payload: val,
  });
};

export const onError = (val) => (dispatch) => {
  dispatch({
    type: ON_ERROR,
    payload: val,
  });
};

export const setErrorMessage = (val) => (dispatch) => {
  dispatch({
    type: SET_ERROR_MESSAGE,
    payload: val,
  });
};
