import React from "react";
import VpsService from "../../Services/VpsService";
import BaseChart from "./BaseChart";

type PropTypes = {
    id: number,
    period: string
}

type StateTypes = {
    cpuData: any,
    diskData: any,
    networkData: any
}
class VpsChart extends React.Component<PropTypes, StateTypes> {
    private vpsService: VpsService;

    constructor(props: any) {
        super(props);

        this.state = {
            cpuData: [],
            diskData: [],
            networkData: [],
        }

        this.vpsService = new VpsService();

        this.getCpuData = this.getCpuData.bind(this);
        this.getCpuDates = this.getCpuDates.bind(this);
    }

    componentDidMount() {
    }

    getCpuDates() {
            const dates: any[] = [];
            return this.vpsService.getCpuData(this.props.id, this.props.period).then((data: any) => {
                data.time_series.cpu.values.forEach((time: number[]) => {
                    const date = new Date(time[0]*1000);
                    dates.push(date.toTimeString().slice(0, 5));
                })
                return (dates);
        });

    }

    getCpuData() {
        const data: any[] = [];
        return this.vpsService.getCpuData(this.props.id, this.props.period).then((response: any) => {
            response.time_series.cpu.values.forEach((value: string[]) => {
                data.push(parseInt(value[1]))
            });
            return data;
        })

    }

    getDiskData() {
        const data: any[] = [];
        return this.vpsService.getDiskData(this.props.id, this.props.period).then((response: any) => {
            response.time_series["disk.0.bandwidth.read"].values.forEach((value: string[]) => {
                data.push(parseInt(value[1]))
            });
            return (data);
        })

    }

    getNetworkData() {
        const data: any[] = [];
         return this.vpsService.getNetworkData(this.props.id, this.props.period).then((response: any) => {
            response.time_series["network.0.pps.in"].values.forEach((value: string[]) => {
                data.push(parseInt(value[1]));
            });
            return data;
        });
    }

    render() {
        return (
            <>
                <BaseChart title='CPU' dates={this.getCpuDates()} data={this.getCpuData()}/>
                <BaseChart title='Disk' dates={this.getCpuDates()} data={this.getDiskData()}/>
                <BaseChart title='Network' dates={this.getCpuDates()} data={this.getNetworkData()}/>
            </>
        );
    }
}

export default VpsChart;