import React from "react";
import Layout from '../../components/public/Layout';

class ForgotPassword extends React.Component {

  renderForm() {
    return (
        <>
          <div className="form-group">
            <input className="form-control" placeholder="Password" type="password" />
          </div>

          <button className="btn btn-primary btn-lg btn-block" type="submit">
            RESET PASSWORD
          </button>
        </>
    );
  }

  render() {
    const title = 'Recover my password';

    return (
        <Layout
            title={title}
            forgotPassword={false}
            register={false}
            login={false}
            forgotPasswordLogin={true}
            // @ts-ignore
            loading={this.state.isLoad}
            children={this.renderForm()}
        />
    );
  }
}

export default ForgotPassword;
