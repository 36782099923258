import React from 'react';

interface FooterProps {
  forgotPassword: boolean;
  register: boolean;
  login: boolean;
  forgotPasswordLogin: boolean;
}

export default function Footer({forgotPassword = false, register = false, login = false, forgotPasswordLogin = false}:FooterProps) {
  return (
      <div className="bottom">
        {forgotPassword ?
            <span className="helper-text m-b-10">
              <i className="fa fa-lock"></i>{' '}
              <a href={`/forgot-password`}>
                Forgot password?
              </a>
            </span>
            :
            <></>
        }
        {login ?
            <span className="helper-text">
              Already have an account?{' '}
              <a href="/login">Login</a>
            </span>

            :
            <></>
        }
        {forgotPasswordLogin ?
            <span className="helper-text">
              Know your password?
              <a href="login">Login</a>
            </span>
            :
            <></>
        }
        {register ?
            <span>
                Don't have an account?{' '}
              <a href="/registration">Register</a>
              </span>
            :
            <></>
        }
      </div>
  );
}
