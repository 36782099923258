import React, {ReactElement} from "react";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import {onHideCloseButton, onModalShow, onDisableCreateButton} from "../../actions";

type PropTypes = {
    title: string;
    closeButtonText: string;
    primaryButtonText: string;
    bodyText?: string;
    bodyElement?: ReactElement;
    onClose?: () => void;
    onSave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onModalShow?: (show: boolean) => void;
    onHideCloseButton?: (isHidden: boolean) => void;
    onDisableCreateButton?: (isDisabled: boolean) => void;
    size?: 'sm' | 'lg' | 'xl';
    show?: boolean;
    isHidden?: boolean;
    isDisabled?: boolean;
}

type StateTypes = {
    onClose: () => void;
    onSave: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

class UIBaseModal extends React.Component<PropTypes, StateTypes> {

    constructor(props:PropTypes = {
        title: '',
        closeButtonText: '',
        primaryButtonText: '',
        size: 'lg',
    }) {

        super(props);
    }


    render() {
        const { title, bodyText, bodyElement, closeButtonText, primaryButtonText, size, show, isHidden, isDisabled, onClose, onSave } = this.props;

        const body = bodyElement ?? bodyText;

        return (
            <Modal size={size} show={show} onHide={onClose}>
                {
                    isHidden
                        ?
                        <Modal.Header>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        :
                        <Modal.Header closeButton>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                }

                <Modal.Body>
                    { body }
                </Modal.Body>

                <Modal.Footer>
                    {
                        !isHidden
                            ?
                        <Button variant="secondary" onClick={onClose}>
                            { closeButtonText }
                        </Button>
                        :
                        null
                    }
                    <Button variant="primary" onClick={onSave} disabled={isDisabled}>
                        { primaryButtonText }
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default UIBaseModal;
