import React, { ReactElement } from 'react';

interface TitleProps {
  title: string|ReactElement;
}

export default function Title({title = ''}:TitleProps) {


  return (
      <div className="header">
        {typeof title === 'string' ? <p className="lead">{title}</p> : title}
      </div>
  );
}

