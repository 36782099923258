import React, {ReactElement} from "react";
import UIBaseModal from "./UIBaseModal";
import {connect} from "react-redux";
import {onDisableCreateButton, onHideCloseButton, onModalShow} from "../../actions";

type PropTypes = {
    title: string;
    closeButtonText: string;
    primaryButtonText: string;
    onSubmit: () => void;
    bodyText?: string;
    bodyElement?: ReactElement;
    size?: 'sm' | 'lg' | 'xl';
    show?: boolean;
    onModalShow?: (val: boolean) => {};
    isHidden?: boolean;
    onHideCloseButton?: (val: boolean) => {};
    isDisabled?: boolean;
    onDisableCreateButton?: (val: boolean) => {};
}

class GeneralModal extends React.Component<PropTypes, {}> {

    constructor(props:PropTypes) {
        super(props);
        this.state = {};

        this.onClose = this.onClose.bind(this);

    }


    onClose() {
        if(typeof this.props.onModalShow === 'undefined') {
            return;
        }

        this.props.onModalShow(false);
    }

    render() {
        const {title, closeButtonText, primaryButtonText, bodyText, bodyElement} = this.props;

        return (
            <UIBaseModal
                title={title}
                closeButtonText={closeButtonText}
                primaryButtonText={primaryButtonText}
                bodyText={bodyText}
                bodyElement={bodyElement}
                show={this.props.show}
                isHidden={this.props.isHidden}
                isDisabled={this.props.isDisabled}
                onSave={this.props.onSubmit}
                onClose={this.onClose}
            />
        );
    }
}

// @ts-ignore
const mapStateToProps = ({modalReducer}) => ({
    show: modalReducer.show,
    isHidden: modalReducer.isHidden,
    isDisabled: modalReducer.isDisabled
});

export default connect(mapStateToProps, {
    onModalShow,
    onHideCloseButton,
    onDisableCreateButton
// @ts-ignore
})(GeneralModal);
