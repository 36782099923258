import React from 'react';
import Layout from '../../components/public/Layout';

class Page500 extends React.Component {
  renderTitle() {
    return (
        <h3>
          <span className="clearfix title">
            <span className="number left">500</span>{' '}
            <span className="text"><br/>Internal Server Error</span>
          </span>
        </h3>
    );
  }

  renderBody() {
    return (
        <>
          <p>
            Apparently we're experiencing an error. But don't worry,
            we will solve it shortly. Please try after some time.
          </p>
          <div className="margin-top-30">
            <a className="btn btn-primary" href="/dashboard">
              <i className="fa fa-home"></i>{' '}
              <span>Home</span>
            </a>
          </div>
        </>

    );
  }

  render() {
    return (
        <Layout
            title={this.renderTitle()}
            forgotPassword={false}
            register={false}
            login={false}
            forgotPasswordLogin={false}
            loading={false}
            children={this.renderBody()}
        />
    );
  }
}

export default Page500;
