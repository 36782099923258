import React, { ReactElement } from 'react';
import MenuStructure from '../../../Models/MenuStructure';
import { Link } from 'react-router-dom';
import NavigationService from "../../../Services/NavigationService";

function activeMenuTabContainer(id:string) {
  const parents = document.getElementById("main-menu");
  const activeMenu = document.getElementById(id);

  if(parents === null || activeMenu === null) {
    return ;
  }

  for (let index = 0; index < parents.children.length; index++) {
    if (parents.children[index].id !== id) {
      parents.children[index].classList.remove("active");
      parents.children[index].children[1].classList.remove("in");
    }
  }

  setTimeout(() => {
    activeMenu.classList.toggle("active");
    activeMenu.children[1].classList.toggle("in");
  }, 10);
}

function renderChildItem(menuItem:MenuStructure, parentMenuItem:MenuStructure) {
  const routeUrl = NavigationService.generateRouteUrl(menuItem.routeUrl, menuItem.routeName, parentMenuItem);
  const key = `${parentMenuItem.routeName}-${menuItem.routeName}`;

  if(!menuItem.showInMenu) {
    return null;
  }

  return (
      <li className={menuItem.active ? "active" : ""} id={key} key={key}>
        <a href={routeUrl}>{menuItem.routeName}</a>
      </li>
  );
}

export default function NavBarSideMenuItem({menuItem}:{menuItem:MenuStructure}) {
  const routeUrl = NavigationService.generateRouteUrl(menuItem.routeUrl, menuItem.routeName, null);

  if(!menuItem.showInMenu) {
    return null;
  }

  return (
      <li className={menuItem.active ? "active" : ""} id={menuItem.routeName} key={menuItem.routeName}>
        <a
            href={routeUrl}
            className={menuItem.children === null ? '' : 'has-arrow'}
            onClick={(e) => {
              e.preventDefault();
              activeMenuTabContainer(menuItem.routeName);
            }}
        >
          <i className={menuItem.icon}></i> <span>{menuItem.routeName}</span>
        </a>
        {menuItem.children === null ? '' : (
            <ul className={menuItem.active ? "collapse in" : "collapse"}>
              { menuItem.children.map( (childMenu: MenuStructure) => {
                return renderChildItem(childMenu, menuItem);
              })}
            </ul>
        )}

      </li>
  )
}


