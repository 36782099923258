import {
    SET_VPS_DETAILS,
    SET_VPS_SERVERS,
    SET_VPS_PERIOD,
} from "../actions";
import VpsData from "../Models/Vps/VpsData";

type VpsReducerState = {
    vpsDetails: VpsData|null,
    vpsServers: VpsData[]|null,
    period: string
}

const initialState:VpsReducerState = {
    vpsDetails: null,
    vpsServers: null,
    period: '15m'
};

type Action = {
    type: string;
    payload: VpsData|VpsData[]
}

const vpsReducer = (state:VpsReducerState = initialState, action: Action) => {
    switch (action.type) {

        case SET_VPS_DETAILS: {
            return {
                ...state,
                vpsDetails: action.payload,
            };
        }

        case SET_VPS_SERVERS: {
            return {
                ...state,
                vpsServers: action.payload,
            };
        }

        case SET_VPS_PERIOD: {
            return {
                ...state,
                period: action.payload
            };
        }

        default:
            return state;
    }
};

export default vpsReducer;
