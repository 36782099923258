import ApiService from './ApiService';
import AccountService from './AccountService';
import TokenService from './TokenService';

export interface TokenObject {
  'access_token': string;
  'token_type': string;
  'expires_in': number;
  'refresh_token': string;
  'scope': string;
}

class AuthService {
  static localStorageAuthKey = 'Authorization';
  static client_id = '528345e98ee56e634fc9bb4552be6890';
  static client_secret = 'eb2c6683d2211bc791b77c2cb901b5cc5a131b087a81d607bfc8e5b5a50b2bb6783a44a3a7b18f4c8d98d8ab9b95fce7adb079c6bd233096cb5561931d8fee71';
  private accountService: AccountService;
  private api: ApiService;

  constructor() {
    this.accountService = new AccountService();
    this.api = new ApiService();
  }

  isLoggedIn(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!TokenService.hasAccessToken()) {
        return resolve(false);
      }
      if (AccountService.hasUserInfo()) {
        return resolve(true);
      }

      // fetch current user
      await this.accountService.getCurrentUser().catch(reject);

      // check if call is success by validating the currentUser information
      if (AccountService.hasUserInfo()) {
        return resolve(true);
      }
      return resolve(false);
    });
  }

  loginUser(username: string, password: string): Promise<TokenObject> | null {
    let formData = new FormData();
    formData.append('client_id', AuthService.client_id);
    formData.append('client_secret', AuthService.client_secret);
    formData.append('grant_type', 'password');
    formData.append('username', username);
    formData.append('password', password);

    return this.tokenCall(formData);
  }

  logoutUser(): void {
    TokenService.removeAccessTokenCookie();
    TokenService.removeRefreshTokenCookie();
    localStorage.removeItem(AuthService.localStorageAuthKey);
    localStorage.removeItem(AccountService.localStorageUserKey);
  }

  lockUser(): void {
    TokenService.removeAccessTokenCookie();
    TokenService.removeRefreshTokenCookie();
    localStorage.removeItem(AuthService.localStorageAuthKey);
  }

  refreshToken(): Promise<TokenObject> | null {
    const refreshToken = TokenService.getRefreshTokenCookie();

    if (refreshToken === null) {
      return null;
    }

    let formData = new FormData();
    formData.append('client_id', AuthService.client_id);
    formData.append('client_secret', AuthService.client_secret);
    formData.append('grant_type', 'refresh_token');
    formData.append('refresh_token', refreshToken);

    return this.tokenCall(formData);
  }

  storeTokenResponse(tokenResponse: TokenObject): TokenObject {
    localStorage.setItem(AuthService.localStorageAuthKey, JSON.stringify(tokenResponse));
    return tokenResponse;
  }

  getTokenResponse(): TokenObject | null {
    const tokenResponse = localStorage.getItem(AuthService.localStorageAuthKey);

    if (tokenResponse !== null) {
      return JSON.parse(tokenResponse);
    }
    return null;
  }

  tokenCall(formData: FormData): Promise<TokenObject> {
    return new Promise((resolve, reject) => {

      // @ts-ignore
      this.api.nonAuthApiCall('/token', 'POST', formData).then((response:TokenObject) => {
        if (typeof response.access_token === 'undefined' || typeof response.expires_in === 'undefined') {
          // reject('Token Call response not valid, response: ' + JSON.stringify(response));
          reject(JSON.stringify({
            response: response,
            status: 400
          }));
        }

        TokenService.storeAccessTokenCookie(response.access_token, response.expires_in);
        TokenService.storeRefreshTokenCookie(response.refresh_token);
        this.storeTokenResponse(response);

        resolve(response);
      }).catch(reject);
    });
  }
}

export default AuthService;
