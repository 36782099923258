import {
  SET_CURRENT_USER,
  UNSET_CURRENT_USER,
} from "../actions";

import UserData from "../Models/UserData";

type UserReducerState = {
  loaded: boolean
  currentUser: UserData|null
}

const initialState:UserReducerState = {
  loaded: false,
  currentUser: null,
};

type Action = {
  type: string;
  payload: UserData
}

const userReducer = (state:UserReducerState = initialState, action: Action) => {
  switch (action.type) {

    case SET_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.payload,
        loaded: true
      };
    }

    case UNSET_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.payload,
        loaded: false
      };
    }

    default:
      return state;
  }
};

export default userReducer;
