import React, { ReactElement } from 'react';
import {connect} from "react-redux";
import UserData from '../../Models/UserData';


type PropTypes = {
    currentUser: UserData;
    currentUserLoaded: boolean;
}

class Dashboard extends React.Component<PropTypes, {}> {
    constructor(props:PropTypes) {
        super(props);
    }

    render() {
        if(!this.props.currentUserLoaded) {
            return 'Loading';
        }
        const user = this.props.currentUser;
        return (
            <>

                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <h2>Dashboard</h2>
                            </div>
                            <div className="body">
                                <p>
                                    <small>Welcome back:  {user.insertion}{user?.insertion !== null ? ' ': null}{user.lastName}, {user.firstName}</small>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        );
    }
}
// @ts-ignore
const mapStateToProps = ({userReducer}) => ({
    currentUser: userReducer.currentUser,
    currentUserLoaded: userReducer.loaded,
});

export default connect(mapStateToProps, {})(Dashboard);
