import React from "react";
import UIBaseModal from "./UIBaseModal";
import AccountService from "../../Services/AccountService";
import {connect} from "react-redux";
import {onActiveCustomerChange, onHideCloseButton, onModalShow} from "../../actions";
import CustomerForm from "../Form/CustomerForm";
import CustomerFormData from "../../Models/CustomerFormData";
import CustomerService from "../../Services/CustomerService";
import customerReducer from "../../reducers/customerReducer";
import CustomerData from "../../Models/CustomerData";
import ToolsService from "../../Services/ToolsService";

type PropTypes = {
    title: string;
    closeButtonText: string;
    primaryButtonText: string;
    bodyText?: string;
    size?: 'sm' | 'lg' | 'xl';
    activeCustomer: CustomerData|null;
    onActiveCustomerChange: (activeCustomer: CustomerData) => {};
}

type StateTypes = {
    formData: CustomerFormData,
    show: boolean,
    hideCloseButton: boolean;
    disableCreateButton: boolean;
}

class CustomerCreateModel extends React.Component<PropTypes, StateTypes> {

    private customerService: CustomerService;
    private accountService: AccountService;

    constructor(props:PropTypes) {
        super(props);

        this.state = {
            formData: {
                companyName: '',
                kvkNumber: '',
                street: '',
                streetNumber: '',
                postalCode: '',
                city: '',
                countryCode: ''
            },
            show: false,
            hideCloseButton: false,
            disableCreateButton: false
        };

        this.customerService = new CustomerService();
        this.accountService = new AccountService();

        this.showModal = this.showModal.bind(this);
        this.hideCloseButton = this.hideCloseButton.bind(this);
        this.userHasCustomers = this.userHasCustomers.bind(this);
        this.setFormData = this.setFormData.bind(this);
        this.createCustomerApiCall = this.createCustomerApiCall.bind(this);
    }

    componentDidMount() {
        this.userHasCustomers().then( hasCustomers => {
            this.showModal(!hasCustomers);
        });
        this.hideCloseButton(true);
    }

    showModal(show: boolean): void {
        this.setState({show: show});
    }

    hideCloseButton(hide: boolean): void {
        this.setState({hideCloseButton: hide});
    }

    userHasCustomers(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const accountService = new AccountService();
            accountService.getCustomersFromUser().then(customers => {
                return resolve(customers !== undefined);
            }).catch(reject);
        });
    }

    setFormData(data: CustomerFormData): void {
        this.setState({['formData']: data});
    }

    getFormData(): CustomerFormData {
        return this.state.formData;
    }

    createCustomerApiCall(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const customerData = this.getFormData();
            this.customerService.createCustomer(customerData).then( (response: boolean) => {
                this.accountService.setCurrentUser().then(user => {
                    if (user.customers !== undefined) {
                        this.accountService.getCustomersFromUser().then( customers => {
                            if (customers) {
                                const customer: CustomerData = customers[0];
                                this.customerService.storeActiveCustomer(customer);
                                this.props.onActiveCustomerChange(customer);
                            }
                        })
                        this.showModal(false);

                        // @todo fix this hotfix in ticket BHP-300
                        ToolsService.navigateTo('/dashboard');
                    }
                }).catch(reject);
                return resolve(response);
            }).catch(reject);
        });
    }

    render() {
        const {title, closeButtonText, primaryButtonText, bodyText} = this.props;

        return (
            <UIBaseModal
                title={title}
                closeButtonText={closeButtonText}
                primaryButtonText={primaryButtonText}
                bodyText={bodyText}
                bodyElement={<CustomerForm sendData={this.setFormData}/>}
                onSave={this.createCustomerApiCall}
                show={this.state.show}
                isHidden={this.state.hideCloseButton}
                isDisabled={this.state.disableCreateButton}
            />
        );
    }
}

// @ts-ignore
const mapStateToProps = ({customerReducer, modalReducer}) => ({
    activeCustomer: customerReducer.activeCustomer,
    show: modalReducer.show,
    isHidden: modalReducer.isHidden,
    isDisabled: modalReducer.isDisabled,
});

export default connect(mapStateToProps, {
    onModalShow,
    onHideCloseButton,
    onActiveCustomerChange
// @ts-ignore
})(CustomerCreateModel);
