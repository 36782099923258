import {SET_MODEL_SHOW, SET_HIDE_CLOSE_BUTTON, SET_DISABLE_CREATE_BUTTON} from "../actions";

type ModalReducerState = {
  show: boolean;
  isHidden: boolean;
  isDisabled: boolean;
}

const initialState:ModalReducerState = {
  show: false,
  isHidden: false,
  isDisabled: false
};

type Action = {
  type: string;
  payload: boolean;
}

const modalReducer = (state:ModalReducerState = initialState, action: Action) => {
  switch (action.type) {

    case SET_MODEL_SHOW: {
      return {
        ...state,
        show: action.payload,
      };
    }
    case SET_HIDE_CLOSE_BUTTON: {
      return {
        ...state,
        isHidden: action.payload
      };
    }
    case SET_DISABLE_CREATE_BUTTON: {
      return {
        ...state,
        isDisabled: action.payload
      };
    }

    default:
      return state;
  }
};

export default modalReducer;
