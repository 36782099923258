import React from 'react';
import { connect } from 'react-redux';
import { onLoggedIn } from '../../actions';
import AuthService from '../../Services/AuthService';
import Layout from '../../components/public/Layout';
import ToolsService from '../../Services/ToolsService';


class Logout extends React.Component {
  constructor(props: {}) {
    super(props);

    this.handleLoginStateChange = this.handleLoginStateChange.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    setTimeout(this.handleLogout, 500);
    setTimeout(this.redirect, 600);
  }

  handleLoginStateChange(state: boolean) {
    // @ts-ignore
    this.props.onLoggedIn(state);
  }

  handleLogout() {
    const authService = new AuthService();
    authService.logoutUser();
    this.handleLoginStateChange(false)
  }

  redirect() {
    ToolsService.navigateTo('/login');
  }

  render() {
    const title = "Logout user";

    return (
        <Layout
            title={title}
            forgotPassword={false}
            register={false}
            login={false}
            forgotPasswordLogin={false}
            // @ts-ignore
            loading={true}
            children={<></>}
        />
    );
  }
}


// @ts-ignore
const mapStateToProps = ({loginReducer}) => ({
  isLoggedIn: loginReducer.isLoggedIn
});

export default connect(mapStateToProps, {
  onLoggedIn,
// @ts-ignore
})(Logout);
