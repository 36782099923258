import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import userReducer from "./userReducer";
import customerReducer from "./customerReducer";
import navigationReducer from "./navigationReducer";
import modalReducer from "./modalReducer";
import analyticalReducer from "./analyticalReducer";
import vpsReducer from "./vpsReducer";

export default combineReducers({
  loginReducer,
  userReducer,
  customerReducer,
  navigationReducer,
  modalReducer,
  analyticalReducer,
  vpsReducer
});
