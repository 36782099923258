import React from "react";
import {Route, Navigate, Routes} from "react-router-dom";
import Login from "../pages/Auth/Login";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import Registration from "../pages/Auth/Registration";

import Page403 from "../pages/Shared/Page403";
import Page404 from "../pages/Shared/Page404";
import Page500 from "../pages/Shared/Page500";
import Page503 from "../pages/Shared/Page503";
import Maintenance from "../pages/Shared/Maintenance";
import Logout from '../pages/Auth/Logout';


export default function UnAuthorized() {
    return (
        <Routes>
            <Route path="/page403" element={<Page403 />} />
            <Route path="/page404" element={<Page404 />} />
            <Route path="/page500" element={<Page500 />} />
            <Route path="/page503" element={<Page503 />} />
            <Route path="/maintenance" element={<Maintenance />} />

            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/logout" element={<Logout />} />

            <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
    );
}
