import MenuStructure from '../Models/MenuStructure'
import FeatureFlagService from './FeatureFlagService'
import UserData from '../Models/UserData'

class NavigationService {
  private readonly featureFlagService: FeatureFlagService

  constructor () {
    this.featureFlagService = new FeatureFlagService()
  }

  public static getActiveMenuItem (menuItems: MenuStructure[]): {
    activeMenuItem: MenuStructure | null
    activeParentMenuItem: MenuStructure | null
  } {
    let activeMenuItem: MenuStructure | null = null
    let activeParentMenuItem: MenuStructure | null = null

    menuItems
      .filter((menuItem: MenuStructure) => menuItem.active)
      .forEach((menuItem: MenuStructure) => {
        activeParentMenuItem = menuItem
        activeMenuItem = menuItem

        if (menuItem.children !== null && menuItem.children.length > 0) {
          menuItem.children.forEach((childMenuItem: MenuStructure) => {
            if (menuItem.active) {
              activeMenuItem = childMenuItem
              return true
            }
          })
        }
      })

    if (activeMenuItem === null) {
      return { activeMenuItem: null, activeParentMenuItem: null }
    }

    return {
      activeMenuItem,
      activeParentMenuItem
    }
  }

  public static cleanUrl (url: string) {
    url = url.endsWith('/') ? url.slice(0, -1) : url
    url = url.startsWith('/') ? url.slice(1) : url
    return url.toLowerCase()
  }

  public static generateRouteUrl (url: string | null, routeName: string, parent: MenuStructure | null): string {
    const parentUrl = (parent !== null ? NavigationService.generateRouteUrl(parent.routeUrl, parent.routeName, null) : '')
    let cleanUrl = url

    if (url === null || cleanUrl === null) {
      return parentUrl + '/' + routeName.replace(' ', '').toLowerCase()
    }
    cleanUrl = cleanUrl.startsWith(parentUrl) ? cleanUrl.replace(parentUrl, '') : cleanUrl
    cleanUrl = NavigationService.cleanUrl(cleanUrl)

    return parentUrl + '/' + cleanUrl
  }

  public static createMenuStructureElement (
    routeName: string,
    icon: string,
    deprecated: boolean = true,
    routeUrl: string | null = null,
    showInMenu: boolean | null = null,
    children: MenuStructure[] | null = null
  ): MenuStructure {
    let childActive = false
    let childShow = false
    const path: string = window.location.pathname
    const active = NavigationService.cleanUrl(path) === NavigationService.cleanUrl(routeUrl ?? '')

    if ((children != null) && children?.length > 0) {
      children.forEach(value => {
        if (value.active) {
          childActive = true
        }
        if (value.showInMenu) {
          childShow = true
        }
      })
    }

    return {
      routeName,
      icon,
      routeUrl,
      active: childActive || active,
      showInMenu: childShow || (showInMenu ?? false),
      children
    }
  }

  public static generateMenuStructure (currentUser: UserData): MenuStructure[] {
    const c = NavigationService.createMenuStructureElement

    const dashboardChildren = [
      c('Page', '', false, '/dashboard', true),
      c('Demographic', '', false),
      c('IoT', '', false)
    ]

    const domainChildren = [
      c('My domains', '', false, '/dashboard'),
      c('Register new domain', '', false, '/buy')
    ]

    const mailChildren = [
      c('Manage mail boxen', '', false, '/boxen'),
      c('Register new mail box', '', false, '/box'),
      c('Manage mail domains', '', false, '/domains'),
      c('Register new mail domain', '', false, '/domain')
    ]

    const webHostingChildren = [
      c('Manage sites', '', false, '/sites'),
      c('Register new site', '', false, '/site')
    ]

    const ordersChildren = [
      c('Manage orders', '', false, '/')
    ]

    const financeChildren = [
      c('Manage payments', '', false, '/payments'),
      c('Manage invoices', '', false, '/invoices/recent'),
      c('Manage quotes', '', false, '/quotes'),
      c('Manage orders', '', false, '/orders')
    ]

    const vpsChildren = [
      c('Manage VPS servers', '', false, '/vps/overview/', FeatureFlagService.checkForVPSFeatureFlag(currentUser), null),
      c('Manage VPS server', '', false, '/vps/details/')
    ]

    return [
      c('Dashboard', 'icon-home', true, '/dashboard', true, dashboardChildren),
      c('Domain', 'icon-grid', false, null, null, domainChildren),
      c('Mail', 'icon-envelope', false, null, null, mailChildren),
      c('Web hosting', 'icon-screen-desktop', false, '/web-hosting', null, webHostingChildren),
      // c('Orders', 'icon-basket', false, null, ordersChildren),
      c('Vps', 'icon-screen-desktop', false, null, null, vpsChildren),
      c('Finance', 'icon-tag', false, null, null, financeChildren)
    ]
  }
}

export default NavigationService
