import React from 'react';
import Layout from '../../components/public/Layout';

class Maintenance extends React.Component {
  renderTitle() {
    return (
        <h3>
          <span className="clearfix title">
            <span className="number left">Maintenance</span>{' '}
            <span className="text"><br />We will be back soon</span>
          </span>
        </h3>
    );
  }

  renderBody() {
    return (
        <>
          <p>
            Sorry for the inconvenience, but we are performing some maintenance at this moment.
            <br /><br />
            If you need to you can always <a href="/contact">contact</a> us,
            <br />
            otherwise we will be back online shortly!
          </p>
          <p>— Bizhost support team <br /><a href="mailto:support@bizhost.nl">support@bizhost.nl</a></p>

        </>

    );
  }

  render() {
    return (
        <Layout
            title={this.renderTitle()}
            forgotPassword={false}
            register={false}
            login={false}
            forgotPasswordLogin={false}
            loading={false}
            children={this.renderBody()}
        />
    );
  }
}

export default Maintenance;
