import React from 'react';


export default function NavBarTopLogo() {
  return (
      <div className="navbar-brand">
        <a href="/dashboard">
          <p style={{marginBottom: '0px'}}>
            <strong>BizHost</strong><span className="sub-title"> - hosting platform</span>
          </p>
        </a>
      </div>
  )
}


