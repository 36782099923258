export const SET_VPS_DETAILS = "vpsReducer/SET_VPS_DETAILS";
export const SET_VPS_SERVERS = "vpsReducer/SET_VPS_SERVERS";
export const SET_VPS_PERIOD = "vpsReducer/SET_VPS_PERIOD";

export const setVpsDetails = (val) => (dispatch) => {
    dispatch({
        type: SET_VPS_DETAILS,
        payload: val,
    });
};
export const setVpsServers = (val) => (dispatch) => {
    dispatch({
        type: SET_VPS_SERVERS,
        payload: val,
    });
};
export const setPeriod = (val) => (dispatch) => {
    dispatch({
        type: SET_VPS_PERIOD,
        payload: val
    })
}
