import React from 'react';
import AuthService from '../../../Services/AuthService';
import { connect } from 'react-redux';
import {
  onLocked,
  onPressEqualizer,
  onPressNotification,
  onPressThemeColor
} from '../../../actions';

interface PropTypes {
  toggleNotification: boolean;
  toggleEqualizer: boolean;
  onPressNotification: () => {};
  onPressEqualizer: () => {};
  onLocked: (state: boolean) => {};
}

class NavBarTopActionButtons extends React.Component<PropTypes, {}> {
  constructor(props: PropTypes | Readonly<PropTypes>) {
    super(props);

    this.lockUser = this.lockUser.bind(this);
  }

  lockUser(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    const authService = new AuthService();
    authService.lockUser();

    this.props.onLocked(true);
  }

  renderAppInbox() {
    return (
        <li>
          <a href="/account/inbox" className="icon-menu d-none d-sm-block">
            <i className="icon-envelope"></i>
            <span className="notification-dot"></span>
          </a>
        </li>
    );
  }

  renderNotification(type: string, text: string, timestamp: string) {
    const key = timestamp.replace(' ', '');
    return (
        <li id={key} key={key}>
          <a>
            <div className="media">
              <div className="media-left">
                <i className={`icon-info ${type}`}></i>
              </div>
              <div className="media-body">
                <p className="text" dangerouslySetInnerHTML={{__html: text}}/>
                <span className="timestamp">{timestamp}</span>
              </div>
            </div>
          </a>
        </li>
    );
  }

  getNotifications(): Array<{
    type: string,
    text: string,
    timestamp: string
  }> {
    return [
      {
        type: 'text-warning',
        text: 'Campaign <strong>Holiday Sale</strong> is nearly reach budget limit.',
        timestamp: '10:00 AM Today'
      },
      {
        type: 'text-success',
        text: 'Your New Campaign <strong>Holiday Sale</strong> is approved.',
        timestamp: '11:30 AM Today'
      },
      {
        type: 'text-info',
        text: 'Website visits from Twitter is 27% higher than last week.',
        timestamp: '04:00 PM Today'
      },
      {
        type: 'text-danger',
        text: 'Error on website analytics configurations',
        timestamp: 'Yesterday'
      }
    ];
  }

  renderNotifications() {
    const notifications = this.getNotifications();
    const show = this.props.toggleNotification ? 'show' : '';

    return (
        <li className={`${show} dropdown`} >
          <a
              href="/account/notifications"
              className="dropdown-toggle icon-menu"
              data-toggle="dropdown"
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                this.props.onPressNotification();
              }}
          >
            <i className="icon-bell"></i>
            <span className="notification-dot"></span>
          </a>

          <ul className={`dropdown-menu notifications ${show}`}>

            <li className="header">
              <strong>You have {notifications.length} new Notifications</strong>
            </li>

            {notifications.map(notification => this.renderNotification(
                notification.type,
                notification.text,
                notification.timestamp
            ))}

            <li className="footer">
              <a className="more">See all notifications</a>
            </li>

          </ul>
        </li>
    );
  }

  renderEqualizer() {
    const show = this.props.toggleEqualizer ? 'show' : '';
    const items = [
      {
        name: 'Mail',
        icon: 'icon-note',
        url: '/account/settings/mail'
      },
      {
        name: 'Preferences',
        icon: 'icon-equalizer',
        url: '/account/settings/preferences'
      },
      {
        name: 'Privacy',
        icon: 'icon-lock',
        url: '/account/settings/privacy'
      },
      {
        name: 'Notifications',
        icon: 'icon-bell',
        url: '/account/settings/notifications'
      }
    ];

    return (
        <li className={`${show} dropdown`}>
          <a
              href="/account/preferences"
              className="dropdown-toggle icon-menu"
              data-toggle="dropdown"
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                this.props.onPressEqualizer();
              }}
          >
            <i className="icon-equalizer"></i>
          </a>
          <ul className={`dropdown-menu user-menu menu-icon ${show}`}>
            <li className="menu-heading">ACCOUNT SETTINGS</li>

            {items.map((item: { name: string, icon: string, url: string }) => {
              return (
                  <li id={item.name} key={item.name}>
                    <a href={item.url}>
                      <i className={item.icon}></i>
                      <span>{item.name}</span>
                    </a>
                  </li>
              );
            })}

          </ul>
        </li>
    );
  }

  renderLockscreen() {
    return (
        <li>
          <a href="/lockscreen" className="icon-menu" onClick={this.lockUser}>
            <i className="icon-lock"></i>
          </a>
        </li>
    );
  }

  renderLogout() {
    return (
        <li>
          <a href="/logout" className="icon-menu">
            <i className="icon-login"></i>
          </a>
        </li>
    );
  }

  render() {
    return (
        <div className="navbar-right">
          <div id="navbar-menu">
            <ul className="nav navbar-nav">
              {/*{this.renderAppInbox()}*/}
              {/*{this.renderNotifications()}*/}
              {/*{this.renderEqualizer()}*/}
              {/*{this.renderLockscreen()}*/}
              {this.renderLogout()}
            </ul>
          </div>
        </div>
    );
  }
}

// @ts-ignore
const mapStateToProps = ({navigationReducer}) => {
  const {
    themeColor,
    generalSetting,
    toggleNotification,
    toggleEqualizer,
    menuProfileDropdown,
    sideMenuTab
  } = navigationReducer;


  return {
    themeColor,
    generalSetting,
    toggleNotification,
    toggleEqualizer,

    menuProfileDropdown, // ?
    sideMenuTab // ?
  };
};

export default connect(mapStateToProps, {

  onPressThemeColor, // @todo fix darkMode
  onPressNotification,
  onPressEqualizer,

  onLocked
  // @ts-ignore
})(NavBarTopActionButtons);
