import React from "react";
import Layout from '../../components/public/Layout';

class Loading extends React.Component {

  render() {
    const title = '';

    return (
        <Layout
            title={title}
            forgotPassword={false}
            register={false}
            login={false}
            forgotPasswordLogin={false}
            // @ts-ignore
            loading={true}
            children={<></>}

        />
    );
  }
}

export default Loading;
