import React, { ReactElement } from 'react';
import NavBarBreadcrumb from './Navbar/NavBarBreadcrumb';
import NavBarTop from './Navbar/NavBarTop';
import NavBarSide from './Navbar/NavBarSide';
import CustomerCreateModal from "../Modals/CustomerCreateModal";
import NavigationService from '../../Services/NavigationService';
import VpsService from '../../Services/VpsService';
import { connect } from 'react-redux';
import UserData from "../../Models/UserData";

interface LayoutProps {
  children: ReactElement;
  currentUser: UserData|null
}

class Layout extends React.Component<LayoutProps, any> {
  private vpsService: VpsService;
  constructor(props: LayoutProps) {
    super(props);

    this.vpsService = new VpsService();
  }



  render(){
    const { currentUser } = this.props;
    if(currentUser === null) {
        return null;
    }

    const menuItems = NavigationService.generateMenuStructure(currentUser);

    return (
      <div id="wrapper">
        {/* Create Customer Modal */}
        <CustomerCreateModal closeButtonText={'close'} primaryButtonText={'Create'} title={'Create Customer'} />

        {/* Top Navigation */}
        <NavBarTop/>

        {/* left Navigation */}
        <NavBarSide menuItems={menuItems} />

        {/* Content Wrapper */}
        <div id="main-content">
          <div
              style={{ flex: 1 }}
              onClick={() => {
                document.body.classList.remove("offcanvas-active");
              }}
          >
            <div>
              <div className="container-fluid">
                <NavBarBreadcrumb menuItems={menuItems} />

                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// @ts-ignore
const mapStateToProps = ({userReducer}) => ({
    currentUser: userReducer.currentUser,
});

export default connect(mapStateToProps, {
// @ts-ignore
})(Layout);