import React from 'react';
import { connect } from 'react-redux';
import {onCurrentUserChange, onError, onLoggedIn, setErrorMessage} from '../../actions';
import AccountService from '../../Services/AccountService';
import AuthService from '../../Services/AuthService';
import Layout from '../../components/public/Layout';
import ToolsService from '../../Services/ToolsService';
import UserData from '../../Models/UserData';
import Alert from 'react-bootstrap/Alert';

interface StateTypes {
  isLoad?: boolean;
  email?: string | null;
  password?: string | null;
}
interface PropTypes {
  onLoggedIn: (state:boolean) => {},
  onCurrentUserChange: (user: UserData) => {}
  onError: (val: boolean) => {},
  isError: boolean;
  setErrorMessage: (val: string|null) => {},
  errorMessage: string|null;
}

class Login extends React.Component<PropTypes, StateTypes> {
  constructor(props: PropTypes | Readonly<PropTypes>) {
    super(props);

    this.state = {
      isLoad: true,
      email: null,
      password: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLoginStateChange = this.handleLoginStateChange.bind(this);
    this.handleCurrentUserChange = this.handleCurrentUserChange.bind(this);
    this.login = this.login.bind(this);
  }

  handleLoginStateChange(state: boolean) {
    this.props.onLoggedIn(state);
  }

  handleCurrentUserChange(user: UserData) {
    this.props.onCurrentUserChange(user);
  }

  componentDidMount() {
    const {email} = this.state;
    const environment = process.env.REACT_APP_ENV ?? 'prod';

    if (email === null && environment === 'dev') {
      this.setState({email: 'customer+orders@bizhost.local', password: 'Customer123!'});
    }

    setTimeout(() => {
      this.setState({
        isLoad: false
      });
    }, 500);
  }

  handleSubmit(e: any) {
    e.preventDefault();
    this.login();
  }

  handleChange(e: React.FormEvent<HTMLInputElement>) {
    const name = e.currentTarget.name as keyof typeof this.state;
    // @ts-ignore
    this.setState({[name]: e.currentTarget.value});
  };

  renderField(
      fieldName: string,
      type: string = 'text',
      required: boolean = false,
      placeholder: string = ''
  ) {
    const ph = (placeholder === '' ? `Your ${fieldName}` : placeholder);
    const fn = fieldName as keyof typeof this.state;
    const value = String(this.state[fn]);

    return (
        <div className="form-group">
          <label className="control-label sr-only">
            {ToolsService.capitalizeFirstLetter(fieldName)}
          </label>
          <input
              className="form-control"
              id={`signup-${fieldName}`}
              name={fieldName}
              placeholder={ph}
              required={required}
              type={type}
              value={value !== 'null' ? value : ''}
              onChange={this.handleChange}
          />
        </div>
    );
  }

  isJson(string: string): boolean {
    try {
      JSON.parse(string);
      return true;
    } catch (e) {
      return false;
    }
  }

  login() {
    const authService = new AuthService();
    const accountService = new AccountService();
    const email = String(this.state.email);
    const password = String(this.state.password);

    // @ts-ignore
    authService.loginUser(email, password).then(() => {
      accountService.getCurrentUser().then((user:UserData) => {
        this.handleCurrentUserChange(user);
        this.handleLoginStateChange(true);
      });
    }).catch((err) => {
      this.props.onError(true);
      if (this.isJson(err)){
        if (JSON.parse(err).status >= 400){
          this.props.setErrorMessage(JSON.parse(err).response.message)
        }else{
          this.props.setErrorMessage('Something went wrong!');
        }
      }else{
        this.props.setErrorMessage('Something went wrong!');
      }
    });
  }

  renderRememberMe() {
    return (
        <div className="form-group clearfix">
          <label className="fancy-checkbox element-left">
            <input type="checkbox"/>
            <span>Remember me</span>
          </label>
        </div>
    );
  }

  renderForm() {
    return (
        <>
          {
            this.props.isError ?
                <Alert variant={'danger'}>
                  {/*Wrong credentials!*/}
                  { this.props.errorMessage }
                </Alert>
                :
                null
          }

          {this.renderField(
              'email',
              'email',
              true
          )}
          {this.renderField(
              'password',
              'password',
              true
          )}
          {this.renderRememberMe()}
          <button onClick={this.handleSubmit} className="btn btn-primary btn-lg btn-block">Login</button>
        </>
    );
  }

  render() {
    const title = 'Login to your account';
    const loaded = (this.state.isLoad === undefined ? false : this.state.isLoad);

    return (
        <Layout
            title={title}
            forgotPassword={true}
            register={true}
            login={false}
            forgotPasswordLogin={false}
            loading={loaded}
            children={this.renderForm()}
        />
    );
  }
}


// @ts-ignore
const mapStateToProps = ({loginReducer}) => ({
  isLoggedIn: loginReducer.isLoggedIn,
  isError: loginReducer.isError,
  errorMessage: loginReducer.errorMessage
});

export default connect(mapStateToProps, {
  onLoggedIn,
  onCurrentUserChange,
  onError,
  setErrorMessage
// @ts-ignore
})(Login);
