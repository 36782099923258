export const SET_MODEL_SHOW = "modalReducer/SET_MODEL_SHOW";
export const SET_HIDE_CLOSE_BUTTON = "modalReducer/SET_HIDE_CLOSE_BUTTON";
export const SET_DISABLE_CREATE_BUTTON = "modalReducer/SET_DISABLE_CREATE_BUTTON";

export const onModalShow = (val) => (dispatch) => {
  dispatch({
    type: SET_MODEL_SHOW,
    payload: val,
  });
};
export const onHideCloseButton = (val) => (dispatch) => {
    dispatch({
      type: SET_HIDE_CLOSE_BUTTON,
      payload: val,
    });
};

export const onDisableCreateButton = (val) => (dispatch) => {
    dispatch({
      type: SET_DISABLE_CREATE_BUTTON,
      payload: val,
    });
};
