import React from "react";
import InvoiceData from "../../Models/InvoiceData";
import InvoiceService from "../../Services/InvoiceService";

type PropTypes = {

}

type StateTypes = {
    invoices: InvoiceData[]|null;
}

class LastInvoices extends React.Component<PropTypes, StateTypes> {

    private invoiceService: InvoiceService;

    constructor(props: PropTypes) {
        super(props);

        this.state = {
            invoices: null
        }

        this.invoiceService = new InvoiceService();
    }

    componentDidMount() {
        this.invoiceService.getInvoicesFromCustomer().then( (invoices: Array<InvoiceData>) => {
            this.invoiceService.filterRecentInvoices(invoices).then( (filteredInvoices: Array<InvoiceData>) => {
                this.setState({['invoices']: filteredInvoices});
            });
        });
    }

    render() {
        return (
            <div className="col-lg-12">
                <div className="card">
                    <div className="header">
                        <h2>
                            My Last Invoices
                            <small>
                                My invoices of the last 3 months
                            </small>
                        </h2>
                    </div>
                    <div className="body table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>INVOICE STATUS</th>
                                <th>CURRENCY</th>
                                <th>TOTAL AMOUNT</th>
                                <th>TOTAL AMOUNT INCL VAT</th>
                                <th>PAY DATE</th>
                                <th>DUE DATE</th>
                                <th>IS RECURRING</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.invoices?.map((invoice, i) => {
                                return (
                                    <tr key={i}>
                                        <th scope="row">{i}</th>
                                        <td>{invoice.invoiceStatus}</td>
                                        <td>{invoice.currency}</td>
                                        <td>{invoice.totalAmount}</td>
                                        <td>{invoice.totalAmountInclVat}</td>
                                        <td>{invoice.payDate}</td>
                                        <td>{invoice.dueDate}</td>
                                        <td>{invoice.isRecurring ? 'Yes' : 'No'}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

export default LastInvoices;