import React, {ChangeEvent} from "react";
import {connect} from "react-redux";
import {onDisableCreateButton, onHideCloseButton, onModalShow} from "../../actions";
import CustomerFormData from "../../Models/CustomerFormData";

type PropTypes = {
    sendData: (data: CustomerFormData) => void;
    onDisableCreateButton: (isDisabled: boolean) => void;
    isDisabled?: boolean;
}

type StateTypes = {
    companyName?: string;
    kvkNumber?: string;
    street?: string;
    streetNumber?: string;
    streetNumberAddition?: string|null;
    city?: string;
    postalCode?: string;
    countryCode?: string;
}

class CustomerForm extends React.Component<PropTypes, StateTypes>
{

    constructor(props: PropTypes) {
        super(props);

        this.state = {
            companyName: undefined,
            kvkNumber: undefined,
            street: undefined,
            streetNumber: undefined,
            streetNumberAddition: null,
            city: undefined,
            postalCode: undefined,
            countryCode: undefined,
        };

        this.handleChange = this.handleChange.bind(this);
        this.disableCreateButton = this.disableCreateButton.bind(this);
        this.formIsFilled = this.formIsFilled.bind(this);
    }

    componentDidMount() {
        this.disableCreateButton(true);
    }

    disableCreateButton(disable: boolean): void {
        this.props.onDisableCreateButton(disable);
    }

    handleChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.currentTarget.name;
        this.setState({[name]: e.currentTarget.value});
        this.disableCreateButton(!this.formIsFilled());
    }

    formIsFilled(): boolean {

        for (const property in this.state) {
            // @ts-ignore
            if(this.state[property] === undefined || this.state[property] === '') {
                return false;
            }
        }

        this.props.sendData(this.state as CustomerFormData);

        return true;
    }

    render() {
        return (
        <div className="card">
            <div className="header">
                <h2> Create your Customer: </h2>
            </div>
            <div className="body">
                <div className="input-group mb-3">
                    <input
                        name="companyName"
                        aria-describedby="basic-addon1"
                        aria-label="CompanyName"
                        className="form-control"
                        placeholder="Company Name"
                        type="text"
                        onChange={this.handleChange}
                        required
                    />
                </div>
                <div className="input-group mb-3">
                    <input
                        name="kvkNumber"
                        aria-describedby="basic-addon1"
                        aria-label="KvkNumber"
                        className="form-control"
                        placeholder="Kvk-number"
                        type="text"
                        onChange={this.handleChange}
                        required
                    />
                </div>
                <div className="input-group mb-3">
                    <input
                        name="street"
                        aria-describedby="basic-addon1"
                        aria-label="Street"
                        className="form-control"
                        placeholder="Street"
                        type="text"
                        onChange={this.handleChange}
                        required
                    />
                </div>
                <div className="input-group mb-3">
                    <input
                        name="streetNumber"
                        aria-describedby="basic-addon1"
                        aria-label="StreetNumber"
                        className="form-control"
                        placeholder="Street Number"
                        type="text"
                        onChange={this.handleChange}
                        required
                    />
                </div>
                <div className="input-group mb-3">
                    <input
                        name="streetNumberAddition"
                        aria-describedby="basic-addon1"
                        aria-label="StreetNumberAddition"
                        className="form-control"
                        placeholder="Street Number Addition"
                        onChange={this.handleChange}
                        type="text"
                    />
                    <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                        * optional
                    </span>
                </div>
                </div>
                <div className="input-group mb-3">
                    <input
                        name="city"
                        aria-describedby="basic-addon1"
                        aria-label="City"
                        className="form-control"
                        placeholder="City"
                        type="text"
                        onChange={this.handleChange}
                        required
                    />
                </div>
                <div className="input-group mb-3">
                    <input
                        name="postalCode"
                        aria-describedby="basic-addon1"
                        aria-label="PostalCode"
                        className="form-control"
                        placeholder="Zip-Code"
                        type="text"
                        onChange={this.handleChange}
                        required
                    />
                </div>
                <div className="input-group mb-3">
                    <input
                        name="countryCode"
                        aria-describedby="basic-addon1"
                        aria-label="CountryCode"
                        className="form-control"
                        placeholder="Country Code"
                        type="text"
                        onChange={this.handleChange}
                        required
                    />
                </div>
            </div>
        </div>
        );
    }

}
// @ts-ignore
const mapStateToProps = ({modalReducer}) => ({
    show: modalReducer.show,
    isHidden: modalReducer.isHidden,
    isDisabled: modalReducer.isDisabled
});

export default connect(mapStateToProps, ({
    onModalShow,
    onHideCloseButton,
    onDisableCreateButton,
}))(CustomerForm);