'use strict';
import CookieService from './CookieService';

class TokenService {
  static accessCookieName = 'SESSION';
  static refreshCookieName = 'REFRESH_TOKEN';

  static hasAccessToken(): boolean {
    return TokenService.getAccessTokenCookie() !== null;
  }

  static getAccessTokenCookie(): string | null {
    return CookieService.getCookie(TokenService.accessCookieName);
  }

  static storeAccessTokenCookie(access_token: string, expiresIn: number): void {
    CookieService.setCookie(TokenService.accessCookieName, access_token, expiresIn);
  }

  static removeAccessTokenCookie(): void {
    CookieService.setCookie(TokenService.accessCookieName, '', -10);
  }

  static hasRefreshToken(): boolean {
    return TokenService.getRefreshTokenCookie() !== null;
  }

  static getRefreshTokenCookie(): string | null {
    return CookieService.getCookie(TokenService.refreshCookieName);
  }

  static storeRefreshTokenCookie(refresh_token: string): void {
    // refresh token is 24 hours valid
    const days = 1;
    const hours = 24;
    const minutes = 60;
    const seconds = 60;
    CookieService.setCookie(TokenService.refreshCookieName, refresh_token, (days * hours * minutes * seconds));
  }

  static removeRefreshTokenCookie(): void {
    CookieService.setCookie(TokenService.refreshCookieName, '', -10);
  }
}

export default TokenService;
