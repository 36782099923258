import {
  SET_ACTIVE_CUSTOMER
} from "../actions";
import CustomerData from "../Models/CustomerData";

type CustomerReducerState = {
  fetchedFromLocalStorage: boolean
  activeCustomer: CustomerData|null
}

const initialState:CustomerReducerState = {
  fetchedFromLocalStorage: false,
  activeCustomer: null,
};

type Action = {
  type: string;
  payload: CustomerData
}

const customerReducer = (state:CustomerReducerState = initialState, action: Action) => {
  switch (action.type) {

    case SET_ACTIVE_CUSTOMER: {
      return {
        ...state,
        activeCustomer: action.payload,
        fetchedFromLocalStorage: true
      };
    }

    default:
      return state;
  }
};

export default customerReducer;
