import CustomerData from "../Models/CustomerData";
import CustomerFormData from "../Models/CustomerFormData";
import ApiService, {ApiResponse} from "./ApiService";
import AccountService from "./AccountService";

class CustomerService {

    static localStorageCustomerKey: string = 'activeCustomer';
    private api: ApiService;
    private accountService: AccountService;

    constructor() {
        this.api = new ApiService();
        this.accountService = new AccountService();
    }

    storeActiveCustomer(customer: CustomerData): CustomerData {
        localStorage.setItem(CustomerService.localStorageCustomerKey, JSON.stringify(customer));
        return customer;
    }

    getActiveCustomer(): Promise<CustomerData|null> {
        return new Promise((resolve, reject) => {
            const activeCustomer: string|null = localStorage.getItem(CustomerService.localStorageCustomerKey);
            if (activeCustomer !== null) {
                return resolve(JSON.parse(activeCustomer));
            }
            return reject(null);
        });
    }

    createCustomer(customer: CustomerFormData): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.accountService.getCurrentUser().then( user => {
                customer.accounts = user.uuid;
                this.api.apiCall('/api/v1/customer', 'POST', JSON.stringify(customer)).then((response:ApiResponse) => {
                    if (response.status !== 200) {
                        return reject(response.meta?.error?.message);
                    }
                    return resolve(true);
                }).catch(reject);
            }).catch(reject);
        });
    }
}

export default CustomerService;