import React, { ReactElement } from 'react';
import Title from './Title';
import Loading from './Loading';
import Logo from './Logo';
import Footer from './Footer';

interface LayoutProps {
  children: ReactElement;
  title: string|ReactElement;
  forgotPassword: boolean;
  register: boolean;
  login: boolean;
  forgotPasswordLogin: boolean;
  loading: boolean;
}

export default function Layout({ children, title, forgotPassword = false, register = false, login = false, forgotPasswordLogin = false, loading = false }: LayoutProps) {
  return (
    <div id="wrapper">
      <div className="theme-cyan">
        <Loading loaded={loading} />

        <div className="hide-border">
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main">
              <div className="auth-box">
                <Logo />
                <div className="card">
                  <Title title={title} />
                  <div className="body">
                    <div className="form-auth-small">
                      { children }
                      <Footer forgotPassword={forgotPassword} register={register} login={login} forgotPasswordLogin={forgotPasswordLogin} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


