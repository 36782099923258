import React, { ReactElement } from 'react';
import MenuStructure from '../../../Models/MenuStructure';
import { Breadcrumb } from 'react-bootstrap';
import NavigationService from '../../../Services/NavigationService';


let toggleMenu = false;

const onToggleMenu = async (toggle:boolean) => {
  toggleMenu = toggle;

  if (!toggle) {
    document.body.classList.remove("layout-fullwidth");
  } else {
    document.body.classList.add("layout-fullwidth");
  }
};

export default function NavBarBreadcrumb({menuItems}:{menuItems:Array<MenuStructure>}) {

  const {activeMenuItem, activeParentMenuItem} = NavigationService.getActiveMenuItem(menuItems);

  return (
      <div className="block-header">
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <h2>
              <a
                  href={activeMenuItem?.routeUrl ?? '/'}
                  className="btn btn-xs btn-link btn-toggle-fullwidth"
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                    e.preventDefault();
                    onToggleMenu(!toggleMenu);
                  }}
              >
                <i
                    className={
                      !toggleMenu
                          ? `fa fa-arrow-left`
                          : "fa fa-arrow-right"
                    }
                ></i>
              </a>{" "}
              { activeMenuItem?.routeName ?? 'Home' }
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">
                  <i className="icon-home"></i>
                </a>
              </li>
              { activeParentMenuItem !== null ?
                  <li className="breadcrumb-item active">
                    <a href={activeParentMenuItem?.routeUrl ?? '/'} >
                      {activeParentMenuItem?.routeName ?? 'Home'}
                    </a>
                  </li>
                  : null
              }
              { activeMenuItem !== activeParentMenuItem && activeParentMenuItem !== null ?
                  <li className="breadcrumb-item active">
                    <a href={activeMenuItem?.routeUrl ?? '/'} >
                      {activeMenuItem?.routeName ?? 'Home'}
                    </a>
                  </li>
                  :
                  null
              }

              {/*@ts-ignore*/}
              {/*{Breadcrumb.map((item: any, index: any) => {*/}
              {/*  return (*/}
              {/*      <li*/}
              {/*          key={item.name + index}*/}
              {/*          className="breadcrumb-item active"*/}
              {/*      >*/}
              {/*        <a href={item.navigate ? item.navigate : null}>*/}
              {/*          {item.name}*/}
              {/*        </a>*/}
              {/*      </li>*/}
              {/*  );*/}
              {/*})}*/}
            </ul>
          </div>

        </div>
      </div>
  )
}


