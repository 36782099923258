import React, {ChangeEvent} from "react";
import VpsData from "../../Models/Vps/VpsData";
import Loading from "../Screens/Loading";
import {Button, Card} from "react-bootstrap";
import {connect} from "react-redux";
import {setVpsDetails, setVpsServers} from "../../actions";
import {Link} from "react-router-dom";
import VpsService from "../../Services/VpsService";
import Alert from "react-bootstrap/Alert";

type PropTypes = {
    vpsDetails: VpsData|null,
    setVpsDetails: (val: VpsData|null) => {},
    vpsServers: VpsData[]|null,
    setVpsServers: (val: VpsData[]|null) => {}
}

type StateTypes = {
    showForm: boolean,
    createRequest: boolean,
    hostname: string
    labels: object,
    newFields: Array<{key: string; value: string;}>,
    errorMessage: string|null
}

class VpsOverview extends React.Component<PropTypes , StateTypes> {

    private vpsService: VpsService;

    constructor(props: PropTypes) {
        super(props);

        this.state = {
            showForm: false,
            createRequest: false,
            hostname: '',
            labels: {},
            newFields: [],
            errorMessage: null
        }


        this.vpsService = new VpsService();

        this.saveVpsDetails = this.saveVpsDetails.bind(this);
        this.submitServer = this.submitServer.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.setLabel = this.setLabel.bind(this);
        this.renderServers = this.renderServers.bind(this);
        this.addLabelField = this.addLabelField.bind(this);
        this.validateLabels = this.validateLabels.bind(this);
    }

    componentDidMount() {
        this.props.setVpsDetails(null);

        this.vpsService.getAllVpsServers().then(servers => {
            this.props.setVpsServers(servers);
        })

        setInterval(() => {
            this.vpsService.getAllVpsServers().then(servers => {
                this.props.setVpsServers(servers);
            });
        }, 60000);
    }

    validateLabels(labels: object): boolean {
        let valid = true;

        Object.keys(labels).forEach((key: string) => {
            if (key.match('^\\S+\\w{8,32}\\S{1,}') === null) {
                this.setState({errorMessage: `key: ${key} is not allowed, no spaces or special characters allowed.`})
                console.log(`key: ${key} is not allowed, no spaces or special characters allowed.`);
                valid = false;
            }
            // @ts-ignore
            if (labels[key].match('^\\S+\\w{8,32}\\S{1,}') === null) {
                // @ts-ignore
                this.setState({errorMessage: `value: ${labels[key]} is not allowed on key: ${key}, no spaces or special characters allowed.`})
                // @ts-ignore
                console.log(`value: ${labels[key]} is not allowed on key: ${key}, no spaces or special characters allowed.`)
                valid = false
            }
        });

        return valid;
    }

    saveVpsDetails(server: VpsData): void {
        this.props.setVpsDetails(server);
    }

    submitServer(): void {
        let labels = this.state.labels;
        this.state.newFields.forEach((label) => {
            if (label.key !== '' && label.value !== '') {
                labels = {
                    ...labels,
                    [label.key]: label.value
                };
            }
        });
        if (this.validateLabels(labels)) {
            this.setState({createRequest: true});
            this.setState({errorMessage: null});
            this.vpsService.createVpsServer(this.state.hostname, labels).then(response => {
                if (response.status === 200) {
                    this.vpsService.getAllVpsServers().then(servers => {
                        this.setState({showForm: !this.state.showForm, createRequest: false, newFields: []})
                        this.props.setVpsServers(servers);
                    });
                }
            });
        }
    }

    handleChange(e: ChangeEvent<HTMLInputElement>): void {
        this.setState({hostname: e.target.value})
    }

    setLabel(key: string, value: string): void {
        this.setState({labels: {
                ...this.state.labels,
                [key]: value
            }});
    }

    copyToClipboard(value: string): void {
        navigator.clipboard.writeText(value);
    }

    renderDateTime(date:string ): string {
        const d = new Date(date);
        return d.toLocaleString('nl-NL', { timeZone: 'Europe/Amsterdam'});
    }

    addLabelField(): void {
        this.setState({newFields: [...this.state.newFields, {key: '', value: ''}]})
    }

    removeLabelField(index: number): void {
        this.state.newFields.splice(index, 1);
        this.setState({newFields: [...this.state.newFields]})
    }

    renderForm() {
        if(!this.state.showForm) {
            return null;
        }
        return (
            <>
            {
                this.state.errorMessage !== null ?
                    <Alert variant={'danger'}>
                        {/*Wrong credentials!*/}
                        { this.state.errorMessage }
                    </Alert>
                    :
                    null
            }
                <div className="card">
                <div className="header">
                    <h2> Create Your Server: </h2>
                </div>

                <div className="body">
                    <label>Hostname: </label>
                    <div className="input-group mb-3">
                        <input
                            name="hostName"
                            aria-describedby="basic-addon1"
                            aria-label="HostName"
                            className="form-control"
                            placeholder="HostName"
                            type="text"
                            onChange={this.handleChange}
                            required
                        />
                    </div>
                    <label>Date: </label>
                    <div className="input-group mb-3">
                        <input
                            name="LicenceEndDate"
                            id="LicenceEndDate"
                            aria-describedby="basic-addon1"
                            aria-label="LicenceEndDate"
                            className="form-control"
                            placeholder="LicenceEndDate"
                            type="date"
                            onChange={(e) => {this.setLabel('LicenceEndDate', new Date(e.target.value).getTime().toString())}}
                        />
                    </div>
                    <label>Customer name: </label>
                    <div className="input-group mb-3">
                        <input
                            name="customerName"
                            id="customerName"
                            aria-describedby="basic-addon1"
                            aria-label="customerName"
                            className="form-control"
                            placeholder="Customer Name"
                            pattern="^\S+\w{8,32}\S{1,}"
                            title="No spaces or special characters allowed"
                            type="text"
                            onChange={(e) => {this.setLabel('ClientName', e.target.value)}}
                        />
                    </div>
                    <label>LinkedIn Account: </label>
                    <div className="input-group mb-3">
                        <input
                            name="linkedInAccount"
                            id="linkedInAccount"
                            aria-describedby="basic-addon1"
                            aria-label="linkedInAccount"
                            className="form-control"
                            placeholder="LinkedIn Account"
                            pattern="^\S+\w{8,32}\S{1,}"
                            title="No spaces or special characters allowed"
                            type="text"
                            onChange={(e) => {this.setLabel('LinkedInAccount', e.target.value)}}
                        />
                    </div>
                    <label>Custom Label: </label><br/>
                    { this.state.newFields.map((field, index) => {
                        return (
                          <div key={index} className="form-group mb3">
                              <div className="input-group">
                                  <input
                                      className="form-control"
                                      placeholder="Label Name"
                                      pattern="^\S+\w{8,32}\S{1,}"
                                      title="No spaces or special characters allowed"
                                      type="text"
                                      value={field.key}
                                      onChange={(e) => { this.state.newFields[index].key = e.target.value; this.setState({newFields: this.state.newFields}); }}
                                  />
                                  <input
                                      className="form-control"
                                      placeholder="Label Value"
                                      pattern="^\S+\w{8,32}\S{1,}"
                                      title="No spaces or special characters allowed"
                                      type="text"
                                      value={field.value}
                                      onChange={(e) => { this.state.newFields[index].value = e.target.value; this.setState({newFields: this.state.newFields}); }}
                                  />
                              </div>
                              <a role='button' onClick={() => {this.removeLabelField(index)}} className="link-danger">---Remove field---</a>
                          </div>
                        );
                    })}
                    {
                        this.state.newFields.length >= 5 ?
                            null :
                            <a role='button' onClick={this.addLabelField} className="link-info" style={{marginTop: "20px"}}> ---Add custom label---</a>
                    }
                    <br /><br/>
                    <Button onClick={this.submitServer} disabled={this.state.createRequest} variant="primary">
                        Submit
                    </Button>
                </div>
            </div>
            </>
        );
    }

    renderServers() {
        if (this.state.showForm) {
            return null;
        }

        return (
            <div className="row" >
                { this.props.vpsServers?.map((server:VpsData, i) => {
                    const status = server.labels.status ?? server.status;
                    return (
                        <div className="col-xl-4 col-lg-6 col-md-12" key={i}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>{server.name}</Card.Title>
                                    <Card.Text>
                                        <strong>Status:</strong> {['initialize', 'finished'].includes(status) ? status: server.status}
                                        <br />

                                        <span>
                                            <strong>IPv4:</strong>
                                            <span role="button" onClick={() => {this.copyToClipboard(server.public_net.ipv4?.ip)}}>{server.public_net.ipv4?.ip ?? 'not available'}</span>
                                            <button title='Copy Ipv4' onClick={() => {this.copyToClipboard(server.public_net.ipv4?.ip)}} type="button" className="btn btn-outline-link btn-sm" style={{ margin: '-8px 0 0 5px', 'padding': 0 }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor" className="bi bi-clipboard"
                                                     viewBox="0 0 16 16">
                                                    <path
                                                        d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                                                    <path
                                                        d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                                                </svg>
                                            </button>
                                        </span>
                                        <br />
                                        <strong>IPv6:</strong> {server.public_net.ipv6?.ip ?? 'not available'}
                                        <br />
                                        <strong>Clientname: </strong> {server.labels.ClientName}
                                        <br />
                                        <strong>Created on:</strong> {this.renderDateTime(server.created)}
                                    </Card.Text>
                                    <Link to={'/vps/details'}>
                                        <Button onClick={() => {this.saveVpsDetails(server)}} variant="primary">More details</Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        if (this.props.vpsServers === null) {
            return <Loading />
        }
        return(
            <div className="col-lg-12">
                    <div className="card">
                        <div className="header d-flex justify-content-between">
                            <h2>
                                VPS
                                <small>
                                    Products & Services overview
                                </small>
                            </h2>
                            <button onClick={() => {this.setState({showForm: !this.state.showForm})}}
                                    className={this.state.showForm ? 'btn btn-danger mr-3' : 'btn btn-primary mr-3'}>
                                {this.state.showForm ? 'Cancel' : 'Create VPS Server'}
                            </button>
                        </div>
                    </div>
                { this.renderForm() }
                { this.renderServers() }
            </div>
        );
    }
}


// @ts-ignore
const mapStateToProps = ({vpsReducer}) => ({
    vpsDetails: vpsReducer.vpsDetails,
    vpsServers: vpsReducer.vpsServers
});

export default connect(mapStateToProps, {
    setVpsDetails,
    setVpsServers
// @ts-ignore
})(VpsOverview);
