import React from "react";
import { connect } from 'react-redux';
import { Dropdown } from "react-bootstrap";
import { onActiveCustomerChange } from '../../../actions';
import CustomerData from "../../../Models/CustomerData";
import CustomerService from "../../../Services/CustomerService";

type PropTypes = {
    fetchedFromLocalStorage: boolean,
    activeCustomer: CustomerData|null
    customers:Array<CustomerData>|undefined
    onActiveCustomerChange: (activeCustomer: CustomerData) => {}
};

class NavBarSideCustomerDetails extends React.Component<PropTypes,{}> {

    constructor(props:PropTypes) {
        super(props);

        this.getActiveCustomer = this.getActiveCustomer.bind(this);
        this.setActiveCustomer = this.setActiveCustomer.bind(this);
        this.onClick = this.onClick.bind(this);
    }


    componentDidMount() {
        const { fetchedFromLocalStorage } = this.props;
        const customerService = new CustomerService();

        if (!fetchedFromLocalStorage) {
            customerService.getActiveCustomer().then(customer => {

                if (customer === null) return null;

                return this.setActiveCustomer(customer);
            });
        }
    }

    getActiveCustomer(): CustomerData|null {
        const { activeCustomer, fetchedFromLocalStorage } = this.props;
        const customerService = new CustomerService();

        if(!fetchedFromLocalStorage) {
            return null;
        }

        if(activeCustomer === null) {
            customerService.getActiveCustomer().then(customer => {

                if (customer === null) return null;

                return this.setActiveCustomer(customer);
            })

            return null;
        }

        return activeCustomer;
    }

    setActiveCustomer(customer:CustomerData) {
        const customerService = new CustomerService();
        customerService.storeActiveCustomer(customer);
        this.props.onActiveCustomerChange(customer);
    }

    onClick(customer:CustomerData) {
        this.setActiveCustomer(customer);
    }

    oneCustomer(customers: Array<CustomerData>) {
        const customerService = new CustomerService();
        const customer: CustomerData|undefined = customers.shift();

        if (customer === undefined) {
            return null;
        }

        customerService.storeActiveCustomer(customer);
        return this.singleCustomer(customer?.companyName)
    }


    loadingCustomers() {
        return this.singleCustomer('loading')
    }

    noCustomers() {
        return this.singleCustomer('-')
    }

    multipleCustomers(customers:Array<CustomerData>) {
        const activeCustomer:CustomerData|null = this.getActiveCustomer();

        return (
            <Dropdown>
                
                <span>Active customer,</span>
                <Dropdown.Toggle
                    variant="none"
                    as="a"
                    id="dropdown-basic"
                    className="user-name"
                >
                    {/*@ts-ignore*/}
                    <strong>{ (activeCustomer !== null ? activeCustomer.companyName : customers[0].companyName) }</strong>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-right account">
                    <Dropdown.Item>
                        { activeCustomer !== null ? activeCustomer.companyName : customers[0].companyName }
                        <li className="divider"></li>
                    </Dropdown.Item>
                    { customers.map((customer:CustomerData, i:number) =>
                        <Dropdown.Item key={i} onClick={() => this.onClick(customer)}>
                            {customer !== activeCustomer ? customer.companyName : ''}
                        </Dropdown.Item>
                        ) }

                </Dropdown.Menu>
            </Dropdown>
        );
    }

    singleCustomer(value:string) {
        return (
            <>
                <span>Active customer,</span>
                <br />
                <strong>{value}</strong>
            </>
        );
    }

    render() {
        // @ts-ignore
        const { customers } = this.props;

        if (customers === undefined) {
            return this.loadingCustomers();
        }
        if (customers?.length > 1) {
            return this.multipleCustomers(customers);
        }
        if (customers?.length === 1) {
            return this.oneCustomer(customers);
        }

        return this.noCustomers();
    }
}


// @ts-ignore
const mapStateToProps = ({customerReducer}) => ({
    fetchedFromLocalStorage: customerReducer.fetchedFromLocalStorage,
    activeCustomer: customerReducer.activeCustomer,
});

export default connect(mapStateToProps, {
    onActiveCustomerChange
// @ts-ignore
})(NavBarSideCustomerDetails);
