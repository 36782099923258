'use strict';

class CookieService {
  static setCookie(cookieName: string, cookieValue: string, expiresSeconds: number) {
    const d = new Date();
    const microSeconds = 1000;
    d.setTime(d.getTime() + (expiresSeconds * microSeconds));
    const expires = `expires=${d.toUTCString()}`;
    const sec = window.location.protocol === 'https:' ? ';secure' : '';
    const domain = `;domain=${window.location.hostname}`;
    document.cookie = `${cookieName}=${cookieValue};${expires};path=/;${sec}${domain};samesite=strict`;
  }

  static getCookie(cookieName: string): string | null {
    let name = cookieName + '=';
    let decodedCookie = decodeURIComponent(document.cookie);

    let dc = decodedCookie.split(';');
    for (let i = 0; i < dc.length; i++) {
      let cookie = dc[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name?.length, cookie?.length);
      }
    }
    return null;
  }
}

export default CookieService;
