import React from 'react';
import Layout from '../../components/public/Layout';

class Page404 extends React.Component {
  renderTitle() {
    return (
        <h3>
          <span className="clearfix title">
            <span className="number left">404</span>{' '}
            <span className="text">Oops! <br />Page Not Found</span>
          </span>
        </h3>
    );
  }

  renderBody() {
    return (
        <>
          <p>The page you were looking for could not be found, please <a href="/contact">contact us</a> to report this issue.</p>
          <div className="margin-top-30">
            <a className="btn btn-default" onClick={() => {
              window.history.go(-1);
              return false;
            }}>
              <i className="fa fa-arrow-left"></i>{' '}
              <span >Go Back</span>
            </a>{' '}
            <a className="btn btn-primary" href="/dashboard">
              <i className="fa fa-home"></i>{' '}
              <span >Home</span>
            </a>
          </div>
        </>

    );
  }

  render() {
    return (
        <Layout
            title={this.renderTitle()}
            forgotPassword={false}
            register={false}
            login={false}
            forgotPasswordLogin={false}
            loading={false}
            children={this.renderBody()}
        />
    );
  }
}

export default Page404;
