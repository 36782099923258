import TokenService from "./TokenService";

export interface ApiResponse {
    data: any;
    meta: {
        version: string;
        api: string;
        auth: string;
        success: boolean;
        info: string;
        error: null|{
            message: string;
            code: number;
        };
        total: number;
    }
    status: number;
}

class ApiService {
    static apiUrl = process.env.REACT_APP_BHP_API_URL ?? 'http://not-configured';

    apiCall(url:string, method:string = 'GET', body:any = null): Promise<ApiResponse> {
        const accessToken = TokenService.getAccessTokenCookie();

        if(accessToken === null) {
            // @todo logic to use the refreshTokenCall

            return new Promise((resolve, reject) => {
                return reject(null);
            });
        }

        return this.call(url, method, body, accessToken);
    }

    nonAuthApiCall(url:string, method:string = 'GET', body:any = null): Promise<ApiResponse>  {
        return this.call(url, method, body);
    }

    call(url:string, method:string = 'GET', body:any = null, accessToken:string|null = null): Promise<ApiResponse> {
        return new Promise((resolve, reject) => {
            const requestOptions:RequestInit = {
                method,
                mode: 'cors',
                headers: {}
            };

            if (url !== '/token') {
                // @ts-ignore
                requestOptions.headers['Content-Type'] = 'application/json';
            }

            if (body !== null) {
                // @ts-ignore
                requestOptions.body = body;
            }

            if (accessToken !== null) {
                // @ts-ignore
                requestOptions.headers.Authorization = `Bearer ${accessToken}`;
            }

            fetch(`${ApiService.apiUrl}${url}`, requestOptions)
                .then(response => {

                    if (response.status === 401) {
                        window.location.href = '/logout'
                        return reject('Session expired')
                    }
                    const jsonResponse = response.json();

                    if(jsonResponse === null) {
                        reject('Could not parse the apiCall Response');
                    }

                    resolve(jsonResponse);
                })
                .catch(reject);
        })
    }
}

export default ApiService;
