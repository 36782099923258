import ApiService, {ApiResponse} from "./ApiService";
import CustomerService from "./CustomerService";
import CustomerData from "../Models/CustomerData";
import InvoiceData from "../Models/InvoiceData";

class InvoiceService {

    private api: ApiService;
    private customerService: CustomerService;

    constructor() {
        this.api = new ApiService();
        this.customerService = new CustomerService();
    }

    getInvoicesFromCustomer(): Promise<Array<InvoiceData>> {
        return new Promise((resolve, reject) => {
            this.customerService.getActiveCustomer().then((activeCustomer: CustomerData | null) => {

                if (activeCustomer === null) {
                    return reject;
                }

                const url = '/api/v1/' + activeCustomer.uuid + '/invoice';

                this.api.apiCall(url).then( (response: ApiResponse) => {
                    return resolve(response.data);
                }).catch(reject);
            }).catch(reject);
        });
    }

    filterRecentInvoices(invoices: Array<InvoiceData>): Promise<Array<InvoiceData>> {
        return new Promise((resolve, reject) => {
            let filteredInvoices: Array<InvoiceData> = [];
            const now: number = Date.now();
            const threeMonthsAgo: number = (now - (((60000*60)*(24))*(30))*3)/1000;
            invoices.map((invoice, i) => {
                if (invoice.createdOn > threeMonthsAgo) {
                    filteredInvoices.push(invoice);
                }
            });

            return resolve(filteredInvoices);
        });
    }
}

export default InvoiceService;