import React from 'react';
import AccountService from '../../Services/AccountService';
import ToolsService from '../../Services/ToolsService';
import Layout from '../../components/public/Layout';
import RegisterUserData from '../../Models/RegisterUserData';

interface StateTypes {
  username?: string | null;
  firstName?: string | null;
  insertion?: string;
  lastName?: string | null;
  email?: string | null;
  password?: string | null;
  message?: string | null;
  error?: boolean;
  success?: boolean;
}

class Registration extends React.Component<{}, StateTypes> {
  constructor(props: {}) {
    super(props);

    // this.state = {
    //   username: null,
    //   firstName: null,
    //   insertion: '',
    //   lastName: null,
    //   email: null,
    //   password: null,
    //   message: null,
    //   error: false,
    //   success: false
    // };

    // @todo remove hacky test credentials!!
    this.state = {
      username: 'SjaakDeWit_'+(Math.random()*123),
      firstName: 'Sjaak',
      insertion: 'de',
      lastName: 'Wit',
      email: 'sjaak@dewit.nl',
      password: 'Wachtwoord098!',
      message: null,
      error: false,
      success: false
    };

    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onSubmitForm(e: React.SyntheticEvent) {
    e.preventDefault();
    this.submitForm();
  };

  submitForm() {
    const accountService = new AccountService();

    const userData: RegisterUserData = {
      username: String(this.state.username),
      firstName: String(this.state.firstName),
      insertion: (this.state.insertion !== '' ? String(this.state.insertion) : null),
      lastName: String(this.state.lastName),
      password: String(this.state.password),
      email: String(this.state.email)
    };

    accountService.registerUser(userData).then(() => {
      console.log('User has been created');

      this.setState({
        message: 'User has been created, please follow instructions in your mail',
        error: false,
        success: true
      });
      // @ts-ignore
      // this.props.history.push('/login');

    }).catch(error => {
      this.setState({message: error, error: true, success: false});
    });
  }

  handleChange(e: React.FormEvent<HTMLInputElement>) {
    const name = e.currentTarget.name as keyof typeof this.state;
    this.setState({[name]: e.currentTarget.value});
  };

  renderField(
      fieldName: string,
      type: string = 'text',
      required: boolean = false,
      placeholder: string = ''
  ) {
    const ph = (placeholder === '' ? `Your ${fieldName}` : placeholder);
    const fn = fieldName as keyof typeof this.state;
    const value = String(this.state[fn]);

    if (this.state.success) {
      return null;
    }

    return (
        <div className="form-group">
          <label className="control-label sr-only">
            {ToolsService.capitalizeFirstLetter(fieldName)}
          </label>
          <input
              className="form-control"
              id={`signup-${fieldName}`}
              name={fieldName}
              placeholder={ph}
              required={required}
              type={type}
              value={value}
              onChange={this.handleChange}
          />
        </div>
    );
  }

  renderErrorMessage() {
    if (!this.state.error) {
      return null;
    }

    return (
        <div className="alert alert-danger" role="alert">
          Error: {String(this.state.message)}
        </div>
    );
  }

  renderSuccessMessage() {
    if (!this.state.success) {
      return null;
    }

    return (
        <div className="alert alert-success" role="alert">
          {String(this.state.message)}
        </div>
    );
  }

  renderSubmitButton() {
    if (this.state.success) {
      return null;
    }

    return (
        <button className="btn btn-primary btn-lg btn-block" type="submit">
          Register
        </button>
    );
  }

  renderForm() {
    return (
        <form
            className="ng-untouched ng-pristine ng-valid"
            onSubmit={this.onSubmitForm}
            method="post"
        >
          {this.renderField(
              'username',
              'text',
              true
          )}

          {this.renderField(
              'firstname',
              'text',
              true
          )}
          {this.renderField(
              'insertion',
              'text'
          )}

          {this.renderField(
              'lastname',
              'text',
              true
          )}
          {this.renderField(
              'email',
              'email',
              true
          )}
          {this.renderField(
              'password',
              'password',
              true
          )}

          {this.renderErrorMessage()}

          {this.renderSuccessMessage()}

          {this.renderSubmitButton()}
        </form>
    );
  }

  render() {
    const title = 'Create an account';
    return (
        <Layout
            title={title}
            forgotPassword={false}
            register={false}
            login={true}
            forgotPasswordLogin={false}
            loading={false}
            children={this.renderForm()}
         />
    );
  }
}

export default Registration;
