import ApiService, { ApiResponse } from './ApiService';
import UserData from '../Models/UserData';
import RegisterUserData from '../Models/RegisterUserData';
import CustomerData from "../Models/CustomerData";

class AccountService {
    static localStorageUserKey = 'currentUser';
    private api: ApiService;

    constructor() {
        this.api = new ApiService();

        this.setCurrentUser = this.setCurrentUser.bind(this);
        this.getCurrentUser = this.getCurrentUser.bind(this);
        this.getCustomersFromUser = this.getCustomersFromUser.bind(this);
        this.registerUser = this.registerUser.bind(this);
    }

    static hasUserInfo():boolean {
        try {
            const currentUserJson = localStorage.getItem(AccountService.localStorageUserKey);
            if (currentUserJson !== null) {
                const currentUser = JSON.parse(currentUserJson);

                return !!(currentUser.uuid && currentUser.email);
            }
        } catch (e) {
            return false;
        }
        return false;
    }

    getCurrentUser():Promise<UserData> {
        return new Promise((resolve, reject) => {
            try{
                const rawCurrentUser = localStorage.getItem(AccountService.localStorageUserKey);

                if (rawCurrentUser !== null) {
                    const currentUser:UserData = JSON.parse(rawCurrentUser);
                    return resolve(currentUser);
                }
            } catch (e) {
                return reject(e);
            }

            this.setCurrentUser().then( (currentUser: UserData) => {
                return resolve(currentUser);
            }).catch(reject)
        });
    }

    setCurrentUser(): Promise<UserData> {
        return new Promise((resolve, reject) => {
            this.api.apiCall('/api/v1/account/info').then((response: ApiResponse) => {
                try {
                    const responseCurrentUser = AccountService.transformResponseDataToUserData(response);

                    localStorage.setItem(AccountService.localStorageUserKey, JSON.stringify(responseCurrentUser));
                    return resolve(responseCurrentUser);
                } catch (e) {
                    return reject(e);
                }
            }).catch(reject);
        });
    }

    getCustomersFromUser(): Promise<Array<CustomerData>|undefined> {
        return new Promise((resolve, reject) => {
            if (AccountService.hasUserInfo()) {
                this.getCurrentUser()
                    .then(user => {
                        if (user.customers) {
                            return resolve(user.customers);
                        }
                        return resolve(undefined);
                    })
                    .catch(reject);
            } else {
                return resolve(undefined);
            }
        });
    }

    registerUser(userData:RegisterUserData):Promise<boolean> {
        return new Promise((resolve, reject) => {

            this.api.nonAuthApiCall('/api/v1/account/create', 'POST', JSON.stringify(userData)).then((response:ApiResponse) => {
                if(response.status !== 200) {
                    return reject(response.meta?.error?.message)
                }
                resolve(true);
            }).catch(reject);
        });
    }

    private static transformResponseDataToUserData(response:ApiResponse):UserData {
        const rawResponseUserData = response.data;

        return {
            uuid: String(rawResponseUserData.uuid),
            username: String(rawResponseUserData.username),
            firstName: String(rawResponseUserData.firstName),
            insertion: (rawResponseUserData.insertion !== '' ? String(rawResponseUserData.insertion) : null),
            lastName: String(rawResponseUserData.lastName),
            email: String(rawResponseUserData.email),
            roles: rawResponseUserData.roles,
            apiToken: String(rawResponseUserData.apiToken),
            customers: rawResponseUserData.customers,
            active: Boolean(rawResponseUserData.active === 1),
            deletedOn: rawResponseUserData.deletedOn !== null ? new Date(rawResponseUserData.deletedOn  * 1000) : null,
            createdOn: new Date(rawResponseUserData.createdOn * 1000),
            updatedOn: rawResponseUserData.updatedOn !== null ? new Date(rawResponseUserData.updatedOn  * 1000) : null,
        }
    }
}

export default AccountService;
