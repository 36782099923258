import React, {ChangeEvent} from "react";
import {connect} from "react-redux";
import {onModalShow, setPeriod, setVpsDetails} from "../../actions";
import {Navigate} from "react-router-dom";
import VpsData from "../../Models/Vps/VpsData";
import VpsService from "../../Services/VpsService";
import VpsChart from "../../components/Chart/VpsChart";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import {ApiResponse} from "../../Services/ApiService";
import CurrencyService from '../../Services/CurrencyService';

type PropTypes = {
    vpsDetails: VpsData,
    setVpsDetails: (val: VpsData|null) => {}
    period: string,
    setPeriod: (val: string) => {},
    show: boolean,
    onModalShow: (val: boolean) => {}
}

type StateTypes = {
    isConfirmed: boolean,
    currentAction: string|null,
    editLabels: boolean,
    labels: object
}

class VpsDetails extends React.Component<PropTypes, StateTypes> {
    private vpsService: VpsService;
    constructor(props: PropTypes) {
        super(props);

        this.state = {
            isConfirmed: false,
            currentAction: null,
            editLabels: false,
            labels: {}
        }

        this.vpsService = new VpsService();

        this.startServer = this.startServer.bind(this);
        this.restartServer = this.restartServer.bind(this);
        this.stopServer = this.stopServer.bind(this);
        this.deleteServer = this.deleteServer.bind(this);
        this.refreshDetails = this.refreshDetails.bind(this);
        this.setVpsPeriod = this.setVpsPeriod.bind(this);
        this.isConfirmed = this.isConfirmed.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.downloadConfig = this.downloadConfig.bind(this);
        this.calculateCurrentPrice = this.calculateCurrentPrice.bind(this);
        this.calculateMonthlyPrice = this.calculateMonthlyPrice.bind(this);
        this.renderLabels = this.renderLabels.bind(this);
        this.editLabels = this.editLabels.bind(this);
        this.onChangeLabel = this.onChangeLabel.bind(this);

    }

    componentDidMount() {
        setInterval(() => {
            this.vpsService.findVpsServer(this.props.vpsDetails.id).then(server => {
                this.props.setVpsDetails(server);
                this.setState({labels: server.labels});
            });
        }, 60000);
    }

    startServer() {
        this.vpsService.startVpsServer(this.props.vpsDetails.id).then(response => {
            this.vpsService.findVpsServer(this.props.vpsDetails.id).then(server => {
                this.props.setVpsDetails(server);
            });
        });
    }

    restartServer(confirmed: boolean) {
        this.setState({currentAction: 'restartServer'});
        this.props.onModalShow(!this.props.show);
        if (confirmed) {
            this.vpsService.restartVpsServer(this.props.vpsDetails.id).then(response => {
                this.vpsService.findVpsServer(this.props.vpsDetails.id).then(server => {
                    this.props.setVpsDetails(server);
                });
            });
        }
    }

    stopServer(confirmed: boolean) {
        this.setState({currentAction: 'stopServer'});
        this.props.onModalShow(!this.props.show);
        if (confirmed) {
            this.vpsService.stopVpsServer(this.props.vpsDetails.id).then(response => {
                this.vpsService.findVpsServer(this.props.vpsDetails.id).then(server => {
                    this.props.setVpsDetails(server);
                });
            });
        }
    }

    deleteServer(confirmed: boolean) {
        this.setState({currentAction: 'deleteServer'});
        this.props.onModalShow(!this.props.show);
        if (confirmed) {
            this.vpsService.deleteVpsServer(this.props.vpsDetails.id).then(response => {
                if (response.status === 200) {
                    this.props.setVpsDetails(null);
                }
            });
            this.setState({isConfirmed: false})
        }

    }

    refreshDetails() {
        this.vpsService.findVpsServer(this.props.vpsDetails.id).then(server => {
            this.props.setVpsDetails(server);
        })
    }

    setVpsPeriod(period: string) {
        this.props.setPeriod(period);
    }

    isConfirmed() {
        switch (this.state.currentAction) {
            case 'deleteServer':
                this.deleteServer(true);
                break;
            case 'restartServer':
                this.restartServer(true);
                break;
            case 'stopServer':
                this.stopServer(true);
                break;
            default:
                break;
        }
    }

    copyToClipboard(value: string): void {
        navigator.clipboard.writeText(value);
    }

    downloadConfig() {
        const ip = this.props.vpsDetails.public_net.ipv4?.ip;
        const name = this.props.vpsDetails.name.trim()
            .replaceAll(' ','-')
            .replace(/[^a-z0-9-]/gi, '')
            .replace(/-+/gi,'-')
            .toLowerCase();
        const connectionFileString = this.vpsService.generateXmlClientConnectionFile(ip);
        const blob = new Blob([connectionFileString], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = `${name}.nxs`;
        link.href = url;
        link.click();
    }

    calculateCurrentPrice(): number {
        const creationDate = new Date(this.props.vpsDetails.created).getTime();
        const currentTimestamp = new Date().getTime();
        const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime();
        let currentPrice = 0;
        let hoursSinceCreated: number;

        if (creationDate > firstDayOfMonth) {
            hoursSinceCreated = (currentTimestamp - creationDate) / 1000 / 60 / 60;
        } else {
            hoursSinceCreated = (currentTimestamp - firstDayOfMonth) / 1000 / 60 / 60;
        }

        this.props.vpsDetails.server_type.prices.forEach(price => {
            if (price.location === this.props.vpsDetails.datacenter.location.name) {
                currentPrice += (parseFloat(price.price_hourly.net) * hoursSinceCreated) * 5;
            }
        });

        currentPrice = Math.floor(currentPrice * 10000) / 10000;

        return currentPrice;
    }

    calculateMonthlyPrice(): number {
        let monthlyPrice = 0;

        this.props.vpsDetails.server_type.prices.forEach(price => {
            if (price.location === this.props.vpsDetails.datacenter.location.name) {
                monthlyPrice += parseFloat(price.price_monthly.net) * 5;
            }
        });

        return monthlyPrice;
    }

    editLabels() {
        this.vpsService.updateVpsServer(this.props.vpsDetails.id, {hostName: this.props.vpsDetails.name, labels: this.state.labels}).then( (response: ApiResponse) => {
            if (response.status === 200) {
                this.setState({editLabels: false});
                this.refreshDetails();
            }
        });
    }

    onChangeLabel(e: ChangeEvent<HTMLInputElement>) {
        this.setState({labels: {...this.props.vpsDetails.labels, [e.target.name]: e.target.value}});

        if (e.target.name.toLowerCase().includes('date')) {
            this.setState({labels: {...this.props.vpsDetails.labels, [e.target.name]: new Date(e.target.value).getTime().toString()}});
        }
    }

    renderLabels() {
        const HIDDEN_LABELS: Array<string> = ["Client", "ManagedBy", "LicenceEndDate", "ClientName", "installation", "LinkedInAccount"];

        if (this.state.editLabels) {
          return (
              <div style={{width: '18em'}}>
                  <div className='m-2 d-flex justify-content-between'>
                      <h2>Labels: </h2>
                      <div className='btn-group'>
                          <button onClick={this.editLabels} className="btn-sm btn btn-info" style={{color: "white"}}>Submit</button>
                          <button onClick={() => {this.setState({editLabels: !this.state.editLabels})}} className="btn-sm btn btn-danger" style={{color: "white"}}>Cancel</button>
                      </div>
                  </div>
                  <div className='m-2'><p><strong>Client Name:</strong> <input name='ClientName' onChange={this.onChangeLabel} className='form-control' type="text" defaultValue={this.props.vpsDetails.labels.ClientName ?? ''}/></p></div>
                  <div className='m-2'><p><strong>LinkedIn Account:</strong> <input name='LinkedInAccount' onChange={this.onChangeLabel} className='form-control' type="text" defaultValue={this.props.vpsDetails.labels.LinkedInAccount ?? ''}/></p></div>
                  <div className='m-2'><p><strong>Licence End Date:</strong> <input name='LicenceEndDate' onChange={this.onChangeLabel} defaultValue={new Date(parseInt(this.props.vpsDetails.labels.LicenceEndDate)).toISOString().split('T')[0]} className='form-control' type="date" /></p></div>
                  {
                      Object.keys(this.props.vpsDetails.labels).map((key, i) => {
                          return HIDDEN_LABELS.includes(key) ? null :
                              <div key={i} className='m-2'><p><strong>{key}: </strong> <input name={key} onChange={this.onChangeLabel} defaultValue={this.props.vpsDetails.labels[key] ?? ''}/></p></div>
                      })
                  }
              </div>
          );
        }
        return (
            <div style={{width: '18em'}}>
                <div className='m-2 d-flex justify-content-between'>
                    <h2>Labels: </h2>
                    <button onClick={() => {this.setState({editLabels: !this.state.editLabels})}} className="btn btn-info" style={{color: "white"}}>Edit</button>
                </div>
                <div className='m-2'><p><strong>Client Name:</strong> {this.props.vpsDetails.labels.ClientName ?? 'not available'}</p></div>
                <div className='m-2'><p><strong>LinkedIn Account:</strong> {this.props.vpsDetails.labels.LinkedInAccount ?? 'not available'}</p></div>
                <div className='m-2'><p><strong>Licence End Date:</strong> {new Date(parseInt(this.props.vpsDetails.labels.LicenceEndDate)).toDateString() ?? 'not available'}</p></div>
                {
                    Object.keys(this.props.vpsDetails.labels).map((key, i) => {
                        return HIDDEN_LABELS.includes(key) ? null :
                            <div key={i} className='m-2'><p><strong>{key}: </strong> {this.props.vpsDetails.labels[key] ?? 'not available'}</p></div>
                    })
                }
            </div>
        );
    }

    render() {
        if (this.props.vpsDetails === null) {
            return <Navigate to={'/vps/overview'} />
        }
        const server = this.props.vpsDetails;
        const installation = server.labels.installation ?? 'unknown';
        return(
            <>
                <ConfirmationModal action={this.state.currentAction} onConfirm={this.isConfirmed} />
                <div className="col-lg-12">
                    <div className="card">
                        <div className="header d-flex justify-content-between">
                            <div>
                                <h2>
                                    {server.name}
                                    <small>
                                        Installation: {['initialize', 'manual', 'running', 'finished','unknown'].includes(installation) ? installation: 'unknown'}
                                        <br />
                                        Status: {server.status}
                                    </small>
                                    <br />
                                    <small>current price: {CurrencyService.format(this.calculateCurrentPrice())}</small>
                                    <small>monthly price: {CurrencyService.format(this.calculateMonthlyPrice())}</small>
                                </h2>
                                <br />
                                <small>NoMachine Connection file</small>
                                <p>
                                    {
                                        (server.public_net.ipv4?.ip ?
                                            <button onClick={this.downloadConfig}
                                                    type="button"
                                                    className="btn btn-sm btn-outline-secondary">
                                                Download
                                            </button>
                                        : null)
                                    }

                                </p>

                            </div>
                            <div>
                                <div className='m-2'><p><strong>RAM:</strong> {server.server_type.memory}GB</p></div>
                                <div className='m-2'><p><strong>IPv4:</strong> {server.server_type.disk}GB</p></div>
                                <div className='m-2'><p><strong>VCPU:</strong> {server.server_type.cores}</p></div>
                                <div className='m-2'><p><strong>IPv4:</strong>
                                     <span role="button" onClick={() => {this.copyToClipboard(server.public_net.ipv4?.ip)}}>{server.public_net.ipv4?.ip ?? 'not available'}</span>

                                    <button title='Copy Ipv4' onClick={() => {this.copyToClipboard(server.public_net.ipv4?.ip)}} type="button" className="btn btn-outline-link btn-sm" style={{ margin: '-8px 0 0 5px', 'padding': 0 }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-clipboard"
                                             viewBox="0 0 16 16">
                                            <path
                                                d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                                            <path
                                                d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                                        </svg>
                                    </button>
                                </p></div>
                                <div className='m-2'><p><strong>Ipv6:</strong> {server.public_net.ipv6?.ip ?? 'not available'}</p></div>
                                <div className='m-2'><p><strong>Created on:</strong> {server.created}</p></div>
                            </div>
                            <div>
                                <div className='m-2'><h2>Actions: </h2></div>
                                <div><button onClick={this.startServer} className="btn btn-primary m-2" disabled={server.status !== 'off'}>Start</button></div>
                                <div><button onClick={() => {this.restartServer(false)}} className="btn btn-success m-2" disabled={server.status !== 'running'}>Restart</button></div>
                                <div><button onClick={() => {this.stopServer(false)}} className="btn btn-danger m-2" disabled={server.status !== 'running'}>Stop</button></div>
                                <div><button onClick={() => {this.deleteServer(false)}} className='btn btn-danger m-2'>Delete Server</button></div>
                            </div>
                            {this.renderLabels()}
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-end">
                    <div className="btn-group me-2 m-2">
                        <button onClick={() => {this.setVpsPeriod('15m')}} type="button" className="btn btn-sm btn-outline-secondary">15m</button>
                        <button onClick={() => {this.setVpsPeriod('3h')}} type="button" className="btn btn-sm btn-outline-secondary">3h</button>
                        <button onClick={() => {this.setVpsPeriod('24h')}} type="button" className="btn btn-sm btn-outline-secondary">24h</button>
                        <button onClick={this.refreshDetails} className="btn btn-sm btn-outline-secondary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                                <path
                                    d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <VpsChart id={server.id} period={this.props.period} />
            </>
        );
    }

}



// @ts-ignore
const mapStateToProps = ({vpsReducer, modalReducer}) => ({
    vpsDetails: vpsReducer.vpsDetails,
    period: vpsReducer.period,
    show: modalReducer.show
});

export default connect(mapStateToProps, {
    setVpsDetails,
    setPeriod,
    onModalShow,
// @ts-ignore
})(VpsDetails);
