import {
  ON_LOGGED_IN,
  ON_LOCKED,
  ON_ERROR,
  SET_ERROR_MESSAGE
} from "../actions";

const initialState = {
  email: "",
  password: "",
  isLoggedIn: null,
  isLocked: false,
  isError: false,
  errorMessage: null
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {

    case ON_LOGGED_IN: {
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    }

    case ON_LOCKED: {
      return {
        ...state,
        isLocked: action.payload,
      };
    }

    case ON_ERROR: {
      return {
        ...state,
        isError: action.payload,
      };
    }

    case SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }

    default:
      return state;
  }
};

export default loginReducer;
