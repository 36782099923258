'use strict';

class ToolsService {
  public static capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public static navigateTo(path:string):void {
    if(window && window.location) {
      window.location.href = path;
      return;
    }
    throw new Error('Could not navigate the required window object is not found');
  }
}

export default ToolsService;
