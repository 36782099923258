import React from "react";
import {connect} from "react-redux";
import { onCurrentUserChange, onLocked, onLoggedIn } from './actions';
import AuthService from "./Services/AuthService";
import Authorized from "./routes/authorized";
import UnAuthorized from "./routes/unauthorized";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import "../node_modules/font-awesome/scss/font-awesome.scss";
import "./assets/assets/scss/main.scss";
import "./assets/assets/scss/color_skins.scss";
import PropTypes from 'prop-types';
import UserData from './Models/UserData';
import AccountService from './Services/AccountService';
import Lockscreen from './pages/Auth/Lockscreen';
import Loading from './pages/Screens/Loading';

// @ts-ignore
window.__DEV__ = true;

interface StateTypes {
    isLoggedIn: boolean;
    currentUser: UserData|null;
}
interface PropTypes {
    history: any;
    isLoggedIn: boolean;
    isLocked: boolean;
    isPageLoaded: boolean;
    onLoggedIn: (state:boolean) => {};
    onCurrentUserChange: (user:UserData) => {};
}

class App extends React.Component<PropTypes, StateTypes> {
    constructor(props: PropTypes | Readonly<PropTypes>) {
        super(props);

        this.state = {
            isLoggedIn: false,
            currentUser: null
        };

        this.checkIsLoggedIn = this.checkIsLoggedIn.bind(this);
        this.setCurrentUser = this.setCurrentUser.bind(this);
        this.onLoggedIn = this.onLoggedIn.bind(this);
    }

    componentDidMount() {
        this.checkIsLoggedIn();
    }

    checkIsLoggedIn() {
        const authService = new AuthService();
        const accountService = new AccountService();

        authService.isLoggedIn()
        .then(result => {
            accountService.getCurrentUser()
                .then( (currentUser) => {
                    this.onLoggedIn(result);
                    this.setCurrentUser(currentUser);
                })
                .catch(error => {
                    if(error !== null) {
                        console.error({error, func: 'accountService.getCurrentUser()'});
                    }
                    this.onLoggedIn(false);
                });
        })
        .catch(error => {
            if(error !== null) {
                console.error({error, func: 'authService.isLoggedIn()'});
            }
            this.onLoggedIn(false);
        });
    }

    onLoggedIn(result: boolean) {
        this.props.onLoggedIn(result);
        this.setState({isLoggedIn: result});
    }

    setCurrentUser(user: UserData) {
        this.props.onCurrentUserChange(user);
    }

    render() {
        const {isLocked, isLoggedIn} = this.props;

        // Set user theme
        document.body.classList.add('theme-cyan');

        if(isLoggedIn === null) {
            return <Loading />;
        }

        if(isLocked) {
            return <Lockscreen />;
        }

        return isLoggedIn ? <Authorized /> : <UnAuthorized/>;
    }
}

// @ts-ignore
const mapStateToProps = ({loginReducer, userReducer, navigationReducer}) => ({
    isLoggedIn: loginReducer.isLoggedIn,
    isLocked: loginReducer.isLocked,
    currentUserLoaded: userReducer.currentUserLoaded,
    isPageLoaded: navigationReducer.isPageLoaded
});

export default connect(mapStateToProps, {
    onLoggedIn,
    onLocked,
    onCurrentUserChange
// @ts-ignore
})(App);
