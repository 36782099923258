import React from 'react';
import MenuStructure from '../../../Models/MenuStructure';
import NavBarSideUserDetails from './NavBarSideUserDetails';
import NavBarSideMenuItem from './NavBarSideMenuItem';
import { Nav } from 'react-bootstrap';

export default function NavBarSide({menuItems}: { menuItems: Array<MenuStructure> }) {
  return (
      <div id="left-sidebar" className="sidebar" style={{zIndex: 9}}>
        <div className="sidebar-scroll">
          <NavBarSideUserDetails/>
          <Nav id="left-sidebar-nav" className="sidebar-nav">
            <ul id="main-menu" className="metismenu">
              {menuItems.map((menuItem: MenuStructure) => {
                return (
                    <NavBarSideMenuItem menuItem={menuItem} key={menuItem.routeName} />
                );
              })}
            </ul>
          </Nav>
        </div>
      </div>
  );
}


