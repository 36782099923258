import React  from 'react';
import { connect } from 'react-redux';
import { onPressSideMenuToggle } from '../../../actions';

type PropTypes = {
  onPressSideMenuToggle: () => {}
}

const NavBarTopMenuButton = (props:PropTypes) => {
  return (
      <div className="navbar-btn">
        <button
            className="btn-toggle-offcanvas"
            onClick={() => {
              props.onPressSideMenuToggle();
            }}
        >
          <i className="lnr lnr-menu fa fa-bars"></i>
        </button>
      </div>
  );
}

// @ts-ignore
const mapStateToProps = ({loginReducer}) => ({
  isLoggedIn: loginReducer.isLoggedIn
});

export default connect(mapStateToProps, {
  onPressSideMenuToggle
// @ts-ignore
})(NavBarTopMenuButton);

